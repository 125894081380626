export class AdminRoutes {
  public static readonly Account = 'account';
  public static readonly MyProfile = 'profile';
  public static readonly UserManagement = 'user-management';
  public static readonly ManageSingleUser = 'user';
  public static readonly ManageSingleCustomer = 'customer';
  public static readonly ClientManagement = 'clients';
  public static readonly ProducerManagement = 'producers';
  public static readonly ContentManagement = 'contents';
  public static readonly SlotsManagement = 'slots';
  public static readonly CustomerSettings = 'settings';
  public static readonly PackageManagement = 'packages';
}
