import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AUTH_TOKEN_KEY } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY);

    if (
      authToken &&
      (req.url.startsWith('api/') || req.url.startsWith('/api/'))
    ) {
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return next.handle(authReq);
    } else {
      // send cloned request with header to the next handler.
      return next.handle(req);
    }
  }
}
