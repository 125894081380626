<div *transloco="let t">
  <h1
    class="text-primary text-28px font-medium leading-43.2px mb-6"
    transloco="Benutzerverwaltung"
  >
    Benutzerverwaltung
  </h1>
  <div
    class="flex w-full border-b-2 gap-4 transition-all duration-500 text-gray19 font-medium bg-gray17 p-1 rounded-lg"
    *isSuperAdmin
  >
    <button
      class="px-6 py-2 rounded-lg"
      [ngClass]="{
        'bg-white text-orange1': queryParams.management_type === 'users'
      }"
      (click)="changeManagementType('users')"
      type="button"
      role="tab"
      aria-controls="user-tab-pane"
      transloco="Benutzer"
    >
      Benutzer
    </button>
    <button
      class="px-4 py-2 rounded-lg"
      [ngClass]="{
        'bg-white text-orange1': queryParams.management_type === 'customers'
      }"
      (click)="changeManagementType('customers')"
      type="button"
      role="tab"
      aria-controls="school-tab-pane"
      transloco="Schulen"
    >
      Schulen
    </button>
  </div>
  <div
    class="mt-10 flex gap-6 items-center"
    *ngIf="queryParams.management_type === 'users'"
  >
    <div class="min-w-[430px] relative">
      <img
        src="assets/imgs/icons/search.svg"
        alt=""
        class="absolute left-4 top-1/2 -translate-y-1/2 z-10"
      />
      <app-input
        [fc]="userSearchControl"
        [placeholder]="t('Suche nach Vorname, E-Mail, Nachname...')"
        classes="ps-10"
      ></app-input>
    </div>
    <div class="min-w-[200px]">
      <app-custom-dropdown [hasRawContent]="true">
        <div
          rawButton
          class="flex gap-1 justify-between items-center border border-gray10 rounded-xl focus:border-gray10 focus:ring-2 focus:ring-gray10 focus:outline-none p-3 text-sm w-full"
        >
          <div class="flex gap-1">
            <img src="assets/imgs/icons/filter.svg" alt="" />
            <div class="text-gray7 font-medium ml-1" transloco="Status:">
              Status:
            </div>
          </div>
          <div class="flex gap-1">
            <div class="text-gray20 font-bold">
              {{ t(userFilterControl.value) }}
            </div>
            <img src="assets/imgs/icons/chevron-down.svg" alt="" class="ml-3" />
          </div>
        </div>
        <ng-container>
          <ul
            class="flex flex-col gap-2 px-3 py-2 rounded-lg bg-white min-w-[230px] shadow"
          >
            <li
              [ngClass]="{
                'bg-gray-100': userFilterControl.value === 'All'
              }"
            >
              <label
                for="userFilterAll"
                transloco="All"
                class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                >All</label
              >
              <input
                type="radio"
                [formControl]="userFilterControl"
                value="All"
                class="hidden"
                id="userFilterAll"
              />
            </li>
            <li
              [ngClass]="{
                'bg-gray-100': userFilterControl.value === 'Aktiv'
              }"
            >
              <label
                for="userFilterEnabled"
                transloco="Aktiv"
                class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                >Aktiv</label
              >
              <input
                type="radio"
                [formControl]="userFilterControl"
                value="Aktiv"
                class="hidden"
                id="userFilterEnabled"
              />
            </li>
            <li
              [ngClass]="{
                'bg-gray-100': userFilterControl.value === 'Inaktiv'
              }"
            >
              <label
                for="userFilterDisabled"
                transloco="Inaktiv"
                class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                >Inaktiv</label
              >
              <input
                type="radio"
                [formControl]="userFilterControl"
                value="Inaktiv"
                class="hidden"
                id="userFilterDisabled"
              />
            </li>
          </ul>
        </ng-container>
      </app-custom-dropdown>
    </div>
    <div class="grow flex justify-end h-fit">
      <a
        class="tw-btn-secondary py-3 flex gap-2 min-w-[200px] justify-center"
        [routerLink]="[ManageSingleUserRoute]"
        *hasCapability="'MANAGE_USERS'"
      >
        <img src="assets/imgs/icons/plus-white.svg" alt="" />
        <span transloco="New User">New User</span>
      </a>
    </div>
  </div>
  <ng-container *isSuperAdmin>
    <div
      class="mt-10 flex gap-6 items-center"
      *ngIf="queryParams.management_type === 'customers'"
    >
      <div class="min-w-[430px] relative">
        <img
          src="assets/imgs/icons/search.svg"
          alt=""
          class="absolute left-4 top-1/2 -translate-y-1/2 z-10"
        />
        <app-input
          [fc]="schoolSearchControl"
          placeholder="Suche nach Name..."
          classes="ps-10"
        ></app-input>
      </div>
      <div class="min-w-[200px]">
        <app-custom-dropdown [hasRawContent]="true">
          <div
            rawButton
            class="flex gap-1 justify-between items-center border border-gray10 rounded-xl focus:border-gray10 focus:ring-2 focus:ring-gray10 focus:outline-none p-3 text-sm w-full"
          >
            <div class="flex gap-1">
              <img src="assets/imgs/icons/filter.svg" alt="" />
              <div class="text-gray7 font-medium ml-1" transloco="Status:">
                Status:
              </div>
            </div>
            <div class="flex gap-1">
              <div class="text-gray20 font-bold">
                {{ t(schoolFilterControl.value) }}
              </div>
              <img
                src="assets/imgs/icons/chevron-down.svg"
                alt=""
                class="ml-3"
              />
            </div>
          </div>
          <ng-container>
            <ul
              class="flex flex-col gap-2 px-3 py-2 rounded-lg bg-white min-w-[230px] shadow"
            >
              <li
                [ngClass]="{
                  'bg-gray-100': schoolFilterControl.value === 'All'
                }"
              >
                <label
                  for="schoolFilterAll"
                  transloco="All"
                  class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                  >All</label
                >
                <input
                  type="radio"
                  [formControl]="schoolFilterControl"
                  value="All"
                  class="hidden"
                  id="schoolFilterAll"
                />
              </li>
              <li
                [ngClass]="{
                  'bg-gray-100': schoolFilterControl.value === 'Aktiv'
                }"
              >
                <label
                  for="schoolFilterEnabled"
                  transloco="Aktiv"
                  class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                  >Aktiv</label
                >
                <input
                  type="radio"
                  [formControl]="schoolFilterControl"
                  value="Aktiv"
                  class="hidden"
                  id="schoolFilterEnabled"
                />
              </li>
              <li
                [ngClass]="{
                  'bg-gray-100': schoolFilterControl.value === 'Inaktiv'
                }"
              >
                <label
                  for="schoolFilterDisabled"
                  transloco="Inaktiv"
                  class="text-gray20 font-bold block cursor-pointer hover:bg-gray-200 p-2 rounded-lg"
                  >Inaktiv</label
                >
                <input
                  type="radio"
                  [formControl]="schoolFilterControl"
                  value="Inaktiv"
                  class="hidden"
                  id="schoolFilterDisabled"
                />
              </li>
            </ul>
          </ng-container>
        </app-custom-dropdown>
      </div>
      <div class="grow flex justify-end h-fit">
        <a
          class="tw-btn-secondary py-3 flex gap-2 min-w-[200px] justify-center"
          [routerLink]="[ManageSingleCustomerRoute]"
          *isSuperAdmin
        >
          <img src="assets/imgs/icons/plus-white.svg" alt="" />
          <span transloco="Neue Schule">Neue Schule</span>
        </a>
      </div>
    </div>
  </ng-container>

  <div *transloco="let t">
    <div *ngIf="queryParams.management_type === 'users'">
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg min-h-[600px]"
      >
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
          <thead class="text-sm font-medium lg:text-base text-primary">
            <tr>
              <th scope="col" class="border-l border-t border-b w-8"></th>
              <th
                scope="col"
                class="border-t border-b border-gray3 pr-6 py-3 text-start"
              >
                <app-sortable-table-header
                  [displayName]="t('Firstname')"
                  fieldName="first_name"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Lastname')"
                  fieldName="last_name"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('E-Mail')"
                  fieldName="email"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Role')"
                  fieldName="role_id"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="border-t border-b border-gray3 pr-6 py-3 text-center"
                *isSuperAdmin
              >
                <app-sortable-table-header
                  [displayName]="t('Schulen')"
                  fieldName="customer_id"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="w-6 border-t border-b border-r border-gray3 pr-6 py-3"
                transloco="Action"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of allUsers"
              class="bg-white text-sm border-b hover:bg-gray-50 lg:text-base text-gray7"
            >
              <td class="px-2 py-4">
                <div class="w-10 h-10">
                  <app-profile-picture
                    [firstName]="user.first_name"
                    [lastName]="user.last_name"
                    [rounded]="true"
                  ></app-profile-picture>
                </div>
              </td>
              <td>{{ user.first_name }}</td>
              <td>{{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <app-role-name-display
                  [roleId]="user.role_id"
                ></app-role-name-display>
              </td>
              <td *isSuperAdmin>
                <app-company-name-display
                  [customerId]="user.customer_id"
                ></app-company-name-display>
              </td>
              <td class="text-xs font-bold">
                <div
                  class="uppercase bg-green6 text-green5 px-3 py-2 flex items-center gap-2 w-fit"
                  *ngIf="user.enabled"
                >
                  <svg
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3.5" cy="3" r="3" fill="#3EBA00" />
                  </svg>

                  <span transloco="Active">Active</span>
                </div>
                <div
                  class="uppercase bg-gray10 text-gray16 px-3 py-2 flex items-center gap-2 w-fit"
                  *ngIf="!user.enabled"
                >
                  <svg
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3.5" cy="3" r="3" fill="#667085" />
                  </svg>

                  <span transloco="Inactive">Inactive</span>
                </div>
              </td>
              <td>
                <app-custom-dropdown
                  [hasRawContent]="true"
                  *hasCapability="'MANAGE_USERS'"
                >
                  <button rawButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <ng-container>
                    <div
                      class="flex flex-col border border-gray3 rounded-lg bg-white min-w-[175px] text-sm lg:text-base font-medium text-primary"
                    >
                      <a
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Bearbeiten')"
                        [routerLink]="'/user-management/user/' + user.id"
                      >
                        <img src="assets/imgs/icons/edit-icon.svg" alt="edit" />
                        {{ t("Bearbeiten") }}
                      </a>
                      <button
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="
                          user.enabled ? t('Deaktivieren') : t('Aktivieren')
                        "
                        (click)="toggleUserActiveState(user.id, user.enabled)"
                      >
                        <ng-container *ngIf="user.enabled">
                          <img src="/assets/imgs/icons/switch_inactive.svg" />
                          {{ t("Deaktivieren") }}
                        </ng-container>
                        <ng-container *ngIf="!user.enabled">
                          <img src="/assets/imgs/icons/switch_active.svg" />
                          {{ t("Aktivieren") }}
                        </ng-container>
                      </button>
                      <button
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Simulieren')"
                        (click)="simulateLogin(user.id)"
                        *isSuperAdmin
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.16681 18.3332H6.60014C5.57338 18.3332 5.05961 18.3332 4.66744 18.1334C4.32248 17.9576 4.04222 17.6771 3.86645 17.3321C3.66663 16.94 3.66663 16.4266 3.66663 15.3998V6.59985C3.66663 5.57309 3.66663 5.05971 3.86645 4.66754C4.04222 4.32258 4.32248 4.04209 4.66744 3.86633C5.05961 3.6665 5.57338 3.6665 6.60014 3.6665H15.4001C16.4269 3.6665 16.9399 3.6665 17.3321 3.86633C17.6771 4.04209 17.9579 4.32258 18.1337 4.66754C18.3335 5.05971 18.3333 5.57309 18.3333 6.59985V9.16652M17.4166 17.4165L14.6666 14.6665M12.375 15.5832C10.603 15.5832 9.16663 14.1468 9.16663 12.3748C9.16663 10.6029 10.603 9.1665 12.375 9.1665C14.1469 9.1665 15.5833 10.6029 15.5833 12.3748C15.5833 14.1468 14.1469 15.5832 12.375 15.5832Z"
                            stroke="#525A6A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        {{ t("Simulieren") }}
                      </button>
                      <button
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Löschen')"
                        (click)="initUserDelete(user)"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.8333 9.16667V15.5833M9.16663 9.16667L9.16663 15.5833M3.66663 5.5H18.3333M16.5 5.5V16.3167C16.5 17.3434 16.5002 17.8569 16.3003 18.249C16.1246 18.594 15.8437 18.8744 15.4988 19.0502C15.1066 19.25 14.5936 19.25 13.5668 19.25H8.43347C7.40671 19.25 6.89294 19.25 6.50077 19.0502C6.15581 18.8744 5.87555 18.594 5.69978 18.249C5.49996 17.8569 5.49996 17.3434 5.49996 16.3167V5.5H16.5ZM14.6666 5.5H7.33329C7.33329 4.64577 7.33329 4.21865 7.47285 3.88173C7.65892 3.43252 8.01559 3.07563 8.4648 2.88955C8.80172 2.75 9.22907 2.75 10.0833 2.75H11.9166C12.7709 2.75 13.198 2.75 13.5349 2.88955C13.9841 3.07563 14.3409 3.43252 14.527 3.88173C14.6665 4.21865 14.6666 4.64578 14.6666 5.5Z"
                            stroke="#525A6A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        {{ t("Löschen") }}
                      </button>
                    </div>
                  </ng-container>
                </app-custom-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalUsers"></app-pagination>
    </div>
    <div *ngIf="queryParams.management_type === 'customers'">
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg min-h-[600px]"
      >
        <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
          <thead class="text-xs 2xl:text-sm text-black">
            <tr>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  class="justify-start"
                  [displayName]="t('Name')"
                  fieldName="label"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                Address
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('PLZ')"
                  fieldName="billing_zip"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Ort')"
                  fieldName="billing_city"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Benutzer')"
                  fieldName="user_count"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Slots')"
                  fieldName="slots"
                ></app-sortable-table-header>
              </th>
              <th scope="col" class="border-t border-b border-gray3 pr-6 py-3">
                <app-sortable-table-header
                  [displayName]="t('Status')"
                  fieldName="enabled"
                ></app-sortable-table-header>
              </th>
              <th
                scope="col"
                class="w-6 border-t border-b border-r border-gray3 pr-6 py-3"
                transloco="Action"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let customer of allCustomers"
              class="bg-white text-sm border-b hover:bg-gray-50 lg:text-base text-gray7"
            >
              <td class="px-2 py-6 text-black">{{ customer.company }}</td>
              <td>
                {{ customer.billing_street }}
                {{ customer.billing_house_number }}
              </td>
              <td>{{ customer.billing_zip }}</td>
              <td>{{ customer.billing_city }}</td>
              <td>{{ customer.user_count }}</td>
              <td>{{ customer.slot_count }}</td>
              <td class="text-xs font-bold">
                <div
                  class="uppercase bg-green6 text-green5 px-3 py-2 flex items-center gap-2 w-fit"
                  *ngIf="customer.enabled"
                >
                  <svg
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3.5" cy="3" r="3" fill="#3EBA00" />
                  </svg>

                  <span transloco="Active">Active</span>
                </div>
                <div
                  class="uppercase bg-gray10 text-gray16 px-3 py-2 flex items-center gap-2 w-fit"
                  *ngIf="!customer.enabled"
                >
                  <svg
                    width="7"
                    height="6"
                    viewBox="0 0 7 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="3.5" cy="3" r="3" fill="#667085" />
                  </svg>

                  <span transloco="Inactive">Inactive</span>
                </div>
              </td>
              <td>
                <app-custom-dropdown
                  [hasRawContent]="true"
                  *hasCapability="'MANAGE_USERS'"
                >
                  <button rawButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                        stroke="#9FA5B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <ng-container>
                    <div
                      class="flex flex-col border border-gray3 rounded-lg bg-white min-w-[175px] text-sm lg:text-base font-medium text-primary"
                    >
                      <a
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Bearbeiten')"
                        [routerLink]="
                          '/user-management/customer/' + customer.id
                        "
                      >
                        <img src="assets/imgs/icons/edit-icon.svg" alt="edit" />
                        {{ t("Bearbeiten") }}
                      </a>
                      <a
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Benutzer hinzufügen')"
                        routerLink="/user-management/user"
                        [queryParams]="{ customer_id: customer.id }"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.75 17.417C13.75 15.3919 11.2876 13.7503 8.25 13.7503C5.21243 13.7503 2.75 15.3919 2.75 17.417M17.4167 14.667V9.16699M14.6667 11.917H20.1667M8.25 11.0003C6.22496 11.0003 4.58333 9.3587 4.58333 7.33366C4.58333 5.30861 6.22496 3.66699 8.25 3.66699C10.275 3.66699 11.9167 5.30861 11.9167 7.33366C11.9167 9.3587 10.275 11.0003 8.25 11.0003Z"
                            stroke="#525A6A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        {{ t("Benutzer hinzufügen") }}
                      </a>
                      <button
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="
                          customer.enabled ? t('Deaktivieren') : t('Aktivieren')
                        "
                        (click)="
                          toggleCustomerActiveState(
                            customer.id,
                            customer.enabled
                          )
                        "
                      >
                        <ng-container *ngIf="customer.enabled">
                          <img src="/assets/imgs/icons/switch_inactive.svg" />
                          {{ t("Deaktivieren") }}
                        </ng-container>
                        <ng-container *ngIf="!customer.enabled">
                          <img src="/assets/imgs/icons/switch_active.svg" />
                          {{ t("Aktivieren") }}
                        </ng-container>
                      </button>
                      <button
                        class="transition-all duration-200 hover:bg-gray-100 flex items-center gap-2 w-full py-3 border-b px-4"
                        [title]="t('Löschen')"
                        (click)="initSchoolDelete(customer)"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.8333 9.16667V15.5833M9.16663 9.16667L9.16663 15.5833M3.66663 5.5H18.3333M16.5 5.5V16.3167C16.5 17.3434 16.5002 17.8569 16.3003 18.249C16.1246 18.594 15.8437 18.8744 15.4988 19.0502C15.1066 19.25 14.5936 19.25 13.5668 19.25H8.43347C7.40671 19.25 6.89294 19.25 6.50077 19.0502C6.15581 18.8744 5.87555 18.594 5.69978 18.249C5.49996 17.8569 5.49996 17.3434 5.49996 16.3167V5.5H16.5ZM14.6666 5.5H7.33329C7.33329 4.64577 7.33329 4.21865 7.47285 3.88173C7.65892 3.43252 8.01559 3.07563 8.4648 2.88955C8.80172 2.75 9.22907 2.75 10.0833 2.75H11.9166C12.7709 2.75 13.198 2.75 13.5349 2.88955C13.9841 3.07563 14.3409 3.43252 14.527 3.88173C14.6665 4.21865 14.6666 4.64578 14.6666 5.5Z"
                            stroke="#525A6A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        {{ t("Löschen") }}
                      </button>
                    </div>
                  </ng-container>
                </app-custom-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination [total]="totalCustomers"></app-pagination>
    </div>
  </div>
</div>
<app-custom-modal
  classes="max-w-[430px]"
  [modalEvents]="modalEvents"
  *transloco="let t"
>
  <div header class="flex gap-4 px-9">
    <div class="bg-gray22 rounded w-fit h-fit p-2">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 10V17M10 10L10 17M4 6H20M18 6V17.8C18 18.9201 18.0002 19.4802 17.7822 19.908C17.5905 20.2844 17.2841 20.5902 16.9078 20.782C16.48 21 15.9203 21 14.8002 21H9.2002C8.08009 21 7.51962 21 7.0918 20.782C6.71547 20.5902 6.40973 20.2844 6.21799 19.908C6 19.4802 6 18.9201 6 17.8V6H18ZM16 6H8C8 5.06812 8 4.60216 8.15224 4.23462C8.35523 3.74456 8.74432 3.35523 9.23438 3.15224C9.60192 3 10.0681 3 11 3H13C13.9319 3 14.3978 3 14.7654 3.15224C15.2554 3.35523 15.6447 3.74456 15.8477 4.23462C15.9999 4.60216 16 5.06812 16 6Z"
          stroke="#525A6A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h2 class="text-black font-medium text-28px">Löschen</h2>
  </div>
  <div content-body class="flex flex-col px-9 mt-6">
    <p class="text-base text-gray7" *ngIf="pendingSchoolToDelete">
      <ng-container transloco="Sind Sie sicher, dass Sie die Schule"
        >Sind Sie sicher, dass Sie die Schule</ng-container
      >
      <span class="font-bold text-primary">
        "{{ pendingSchoolToDelete.name }}"
      </span>
      <ng-container transloco="löschen möchten?">löschen möchten?</ng-container>
    </p>
    <p class="text-base text-gray7" *ngIf="pendingUserToDelete">
      <ng-container transloco="Sind Sie sicher, dass Sie den Benutzer"
        >Sind Sie sicher, dass Sie den Benutzer</ng-container
      >
      <span class="font-bold text-primary">
        "{{ pendingUserToDelete?.name }}"
      </span>
      <ng-container transloco="löschen möchten?">löschen möchten?</ng-container>
    </p>
    <div class="flex justify-between gap-6 mt-16 mb-12">
      <button
        transloco="Abbrechen"
        class="tw-btn-secondary-outline min-w-[140px]"
        (click)="hideDeleteModal()"
      >
        Abbrechen
      </button>
      <button
        transloco="Löschen"
        class="tw-btn-secondary min-w-[140px]"
        (click)="handleDeleteFromModal()"
      >
        Löschen
      </button>
    </div>
  </div>
</app-custom-modal>
