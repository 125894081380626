import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { createPopper } from '@popperjs/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements AfterViewInit, OnDestroy {
  @Input() buttonText?: string;
  @Input() hasError?: boolean;
  @Input() hasRawContent?: boolean;

  @ViewChild('dropdownButton') dropdownButton?: ElementRef;
  @ViewChild('dropdownList') dropdownList?: ElementRef;

  initialized = false;
  showDropdown = false;
  private popperInstance: any;
  contentWidth = 100;

  private closeTimeout: any;

  ngAfterViewInit() {
    this.popperInstance = createPopper(
      this.dropdownButton?.nativeElement,
      this.dropdownList?.nativeElement,
      {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      }
    );

    this.contentWidth = this.dropdownButton?.nativeElement?.offsetWidth;
    setTimeout(() => {
      this.initialized = true;
    }, 1000)
  }

  // close the dropdown if mouse did not return within 800ms
  onMouseLeave() {
    this.closeTimeout = setTimeout(() => (this.showDropdown = false), 800);
  }

  onMouseEnter() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown) {
      this.popperInstance.update();
    }
  }

  ngOnDestroy() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
  }
}
