import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'transformTimestamp',
})
export class TransformTimestampPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]) {
    const today = dayjs();
    const targetDay = dayjs(value);
    let formatString = 'DD. MMMM, HH:mm';

    if (today.diff(targetDay, 'month') >= 6) {
      formatString = 'DD. MMMM YY, HH:mm';
    }

    return targetDay.format(formatString);
  }
}
