import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransformNamePipe } from './transform-name.pipe';
import { TransformTimestampPipe } from './transform-timestamp.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
  declarations: [TransformNamePipe, TransformTimestampPipe, FileSizePipe],
  imports: [CommonModule],
    exports: [TransformNamePipe, TransformTimestampPipe, FileSizePipe],
})
export class PipesModule {}
