import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent {
  @Input() inputType = 'text';
  @Input() label!: string;
  @Input() fc!: any;
  @Input() placeholder = "";
  @Input() hasError?: boolean;
  @Input() errorMessage?: string;
  constructor() {}
}
