import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CreateFolderModalService } from '../create-folder-modal/create-folder-modal.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  RequestFolder,
  RequestFolderService,
} from '../../backend-services/request-folder.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import {
  AppContent,
  ContentService,
} from '../../backend-services/content.service';
import { ContentPreviewModalService } from '../content-preview-modal/content-preview-modal.service';
import { AuthService } from '../../backend-services/auth.service';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-contents-list-view-page',
  templateUrl: './contents-list-view-page.component.html',
  styleUrls: ['./contents-list-view-page.component.scss'],
})
export class ContentsListViewPageComponent implements OnInit {
  public statusFilterControl = new FormControl('All');
  public searchControl = new FormControl('');
  queryParams: any = {};
  currentFolderId?: number = undefined;
  requestFolders: RequestFolder[] = [];
  contents: AppContent[] = [];
  totalContentsCount = 0;
  currentFolderData?: RequestFolder = undefined;

  deleteContentModalEvents = new Subject<ModalEvents>();
  contentToDelete?: AppContent;

  isLoading: boolean = false;

  constructor(
    private createFolderModalService: CreateFolderModalService,
    public translocoService: TranslocoService,
    private requestFolderService: RequestFolderService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private contentPreviewModalService: ContentPreviewModalService,
    private authService: AuthService,
    private contentService: ContentService
  ) {
    this.createFolderModalService.modalEvents.subscribe((ev) => {
      if (ev.type === 'updated') {
        this.handleRequestFetch();
        this.fetchCurrentFolder();
      }

      if (ev.type === 'deleted') {
        // go back one step if folder is deleted
        if (
          this.currentFolderData &&
          this.currentFolderData.path!.length >= 2
        ) {
          const parentId = this.currentFolderData.path!.slice(-2, 1)[0].id;
          this.router.navigate(['contents', 'folders', parentId], {
            replaceUrl: true,
          });
        } else {
          this.router.navigate(['contents'], { replaceUrl: true });
        }
      }
    });

    this.deleteContentModalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.deleteContentModalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.contentToDelete = undefined;
          break;
      }
    });

    // listen to changes in the search control
    this.searchControl.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (value) {
          this.queryParams.search = value;
        } else {
          this.queryParams.search = undefined;
        }

        this.handleQueryParamChange();
      });

    // listen to filter changes
    this.statusFilterControl.valueChanges.subscribe((v) => {
      if (v === 'Aktiv') {
        this.queryParams.filter_status = 'enabled';
      } else if (v === 'Inaktiv') {
        this.queryParams.filter_status = 'disabled';
      } else {
        this.queryParams.filter_status = undefined;
      }
      this.handleQueryParamChange();
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const folderId = params.get('request_folder_id');
      if (folderId) {
        this.currentFolderId = parseInt(folderId);
        this.fetchCurrentFolder();
      } else {
        this.currentFolderData = undefined;
        this.currentFolderId = undefined;
      }

      this.route.queryParamMap.subscribe((params) => {
        let queryParams: Record<string, string | null> = {};
        params.keys.forEach((k) => {
          queryParams[k] = params.get(k);
        });

        this.queryParams = queryParams;

        this.searchControl.setValue(this.queryParams.search);
        if (this.queryParams.filter_status === 'enabled') {
          this.statusFilterControl.setValue('Aktiv');
        } else if (this.queryParams.filter_status === 'disabled') {
          this.statusFilterControl.setValue('Inaktiv');
        } else {
          this.statusFilterControl.setValue('All');
        }

        this.handleRequestFetch();
      });
    });
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  async openCreateFolderModal() {
    while (this.currentFolderId && !this.currentFolderData) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    this.createFolderModalService.modalEvents.next({
      type: 'new',
      currentFolder: this.currentFolderId
        ? {
            id: this.currentFolderId,
            customer_id: this.currentFolderData!.customer_id,
          }
        : undefined,
    });
  }

  openFolderEditModal(folderId: number) {
    this.createFolderModalService.modalEvents.next({
      type: 'edit',
      folder_id: folderId,
    });
  }

  openDeleteFolderModal(folderId: number) {
    this.createFolderModalService.deleteConfirmModalEvents.next({
      type: 'init-delete',
      folder_id: folderId,
    });
  }

  handleRequestFetch() {
    this.fetchRequestFolders();
  }

  fetchRequestFolders() {
    this.requestFolderService
      .filterRequestFolders({
        ...this.queryParams,
        currentFolderId: this.currentFolderId || undefined,
      })
      .subscribe((c) => {
        this.requestFolders = c.data.folders;
        this.contents = c.data.contents;
        this.totalContentsCount = c.data.total;
      });
  }

  fetchCurrentFolder() {
    if (this.currentFolderId) {
      this.requestFolderService.getFolderById(this.currentFolderId).subscribe({
        next: (res) => {
          this.currentFolderData = res.data;
        },
        error: () => {
          this.currentFolderData = undefined;
          this.toastr.error(
            this.translocoService.translate(`'Request folder not found`)
          );
        },
      });
    }
  }

  openPreviewContentModal(data: AppContent) {
    this.contentPreviewModalService.modalEvents.next({ type: 'init', data });
  }

  openContentDeleteModal(data: AppContent) {
    this.contentToDelete = data;
    this.deleteContentModalEvents.next({ type: 'open' });
  }

  _gotoInhalteEditorLink() {
    let link = `/inhalte-editor?`;
    if (this.currentFolderId) {
      link += `&request_folder_id=${this.currentFolderId}`;
    }

    // add customer id for super admins
    if (this.authService.isSuperAdmin.value) {
      if (!this.currentFolderData) {
        this.toastr.error(
          this.translocoService.translate('Action not defined for super admins')
        );
        return;
      }

      link += `&customer_id=${this.currentFolderData.customer_id}`;
    }

    this.isLoading = false;
    window.location.href = link;
  }

  openNewInhalteEditor() {
    this.isLoading = true;
    sessionStorage.removeItem('editor_session');
    this._gotoInhalteEditorLink();
  }

  editInhalte(content: AppContent) {
    this.isLoading = true;
    this.contentService.getContentById(content.id).subscribe((res) => {
      const sessionObject = {
        id: res.data.id,
        customer_id: res.data.customer_id,
        data: res.data.data,
        title: res.data.title,
      };

      sessionStorage.setItem('editor_session', JSON.stringify(sessionObject));
      this._gotoInhalteEditorLink();
    });
  }

  copyInhalte(content: AppContent) {
    this.isLoading = true;
    this.contentService.getContentById(content.id).subscribe((res) => {
      const sessionObject = {
        customer_id: res.data.customer_id,
        data: res.data.data,
        title: `${res.data.title} (Copy)`,
      };

      sessionStorage.setItem('editor_session', JSON.stringify(sessionObject));
      this._gotoInhalteEditorLink();
    });
  }

  closeDeleteModal() {
    this.deleteContentModalEvents.next({ type: 'close' });
  }

  handleContentDelete() {
    if (this.contentToDelete) {
      this.contentService
        .deleteContent(this.contentToDelete.id)
        .subscribe((res) => {
          this.requestFolderService.clearCache();
          this.fetchRequestFolders();
          this.toastr.info(this.translocoService.translate('Inhalte deleted'));
          this.closeDeleteModal();
        });
    }
  }

  copyFolder(id: number) {
    this.requestFolderService.copyFolder(id).subscribe((res) => {
      this.requestFolderService.clearCache();
      this.toastr.info(this.translocoService.translate('Folder copied'));
      this.handleRequestFetch();
    });
  }
}
