<div class="flex flex-col">
  <app-label class="pb-1" [attrFor]="label">
    {{ label }}
  </app-label>
  <app-input
    [placeholder]="placeholder"
    [type]="inputType"
    [fc]="fc"
    [hasError]="hasError"
    [id]="label"
  ></app-input>
  <div *ngIf="hasError" class="text-sm text-semantic-danger-500">
    {{ errorMessage }}
  </div>
</div>
