export function calculateContainerSize() {
  const height = Math.min(900, window.innerHeight * 0.6);
  const width = 0.5625 * height;

  return  {
    containerHeight: `${height}px`,
    containerWidth: `${width}px`,
    left: `${width * 0.025445293}px`,
    right: `${width * 0.025445293}px`,
    top: `${height * 0.007692308}px`,
    bottom: `${height * 0.147435897}px`,
    width: `${width - width * 0.025445293 - width * 0.025445293}px`,
    height: `${height - height * 0.007692308 - height * 0.147435897}px`,
  };
}

export const  DEFAULT_PREVIEW_IMG_PARAMS = {
  containerHeight: '900px',
  containerWidth: '506.25px',
  left: '12.881679581px',
  right: '12.881679581px',
  top: '6.9230772px',
  bottom: '132.6923073px',
  width: '480.486640838px',
  height: '760.3846155px',
};
