import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentsListViewPageComponent } from './contents-list-view-page/contents-list-view-page.component';
import { ContentManagementLayoutComponent } from './content-management-layout/content-management-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { CreateFolderModalComponent } from './create-folder-modal/create-folder-modal.component';
import { ContentPreviewModalComponent } from './content-preview-modal/content-preview-modal.component';

const contentManagementRoutes: Routes = [
  {
    path: '',
    component: ContentManagementLayoutComponent,
    children: [{ path: '', component: ContentsListViewPageComponent }],
  },
];

@NgModule({
  declarations: [
    ContentsListViewPageComponent,
    ContentManagementLayoutComponent,
    CreateFolderModalComponent,
    ContentPreviewModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(contentManagementRoutes),
    ReactiveFormsModule,
    DirectivesModule,
    TranslocoModule,
    SharedComponentsModule,
  ],
  exports: [CreateFolderModalComponent],
})
export class ContentManagementModule {}
