import { Component, HostListener, OnInit } from '@angular/core';
import { ContentPreviewModalService } from './content-preview-modal.service';
import {calculateContainerSize, DEFAULT_PREVIEW_IMG_PARAMS} from "../../../util/preview-params-calculator";

@Component({
  selector: 'app-content-preview-modal',
  templateUrl: './content-preview-modal.component.html',
  styleUrls: ['./content-preview-modal.component.scss'],
})
export class ContentPreviewModalComponent implements OnInit {
  imgParams = DEFAULT_PREVIEW_IMG_PARAMS;

  constructor(public contentPreviewModalService: ContentPreviewModalService) {}

  ngOnInit(): void {
    this.imgParams = calculateContainerSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.imgParams = calculateContainerSize();
  }

}
