<div class="flex items-center">
  <div>{{ displayName }}</div>
  <button
    class="sort link-icon-svg-grey ghost-btn transition-all fill-gray-500"
    [ngClass]="{
      'rotate-up': queryParams.sortDir === 'desc'
    }"
    *ngIf="queryParams.sortBy === fieldName"
    (click)="changeSort()"
  >
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 6.78125L6 10.1562L9.375 6.78125M6 9.6875V2.84375"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
  <button
    class="sort link-icon-svg-grey ghost-btn transition-all fill-gray-300"
    *ngIf="queryParams.sortBy !== fieldName"
    (click)="changeSort()"
  >
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 6.78125L6 10.1562L9.375 6.78125M6 9.6875V2.84375"
        class="stroke-gray-400"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>
