<app-custom-modal
  classes="max-w-[444px]"
  [modalEvents]="createFolderModalService.modalEvents"
  *transloco="let t"
>
  <div header class="flex gap-4 px-9">
    <h2
      class="text-black font-medium text-28px"
      *ngIf="!createFolderModalService.isEditing"
      transloco="Create Folder"
    >
      Create Folder
    </h2>
    <h2
      class="text-black font-medium text-28px"
      *ngIf="createFolderModalService.isEditing"
      transloco="Edit Folder"
    >
      Edit Folder
    </h2>
  </div>
  <div content-body class="flex flex-col px-9 mt-6">
    <div class="flex flex-col my-4 gap-2">
      <!--      is part allows to change parent folder-->
      <!--      <ng-container *ngIf="createFolderModalService.isEditing">-->
      <!--        <label class="font-semibold text-lg" transloco="Parent Folder"-->
      <!--          >Parent Folder</label-->
      <!--        >-->
      <!--        <app-custom-dropdown-->
      <!--          [buttonText]="-->
      <!--            createFolderModalService.parentIdControl.value-->
      <!--              ? requestFolderService.localFolderNameMap.get(-->
      <!--                  createFolderModalService.parentIdControl.value-->
      <!--                )-->
      <!--              : '/'-->
      <!--          "-->
      <!--        >-->
      <!--          <ng-template #recursiveList let-list>-->
      <!--            <li *ngFor="let requestFolder of list" class="border-l">-->
      <!--              <button-->
      <!--                class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"-->
      <!--                (click)="changeParentFolderValue(requestFolder.id)"-->
      <!--              >-->
      <!--                {{ requestFolder.title }}-->
      <!--              </button>-->
      <!--              <ul *ngIf="requestFolder.children?.length" class="ml-3">-->
      <!--                <ng-container-->
      <!--                  *ngTemplateOutlet="-->
      <!--                    recursiveList;-->
      <!--                    context: { $implicit: requestFolder.children }-->
      <!--                  "-->
      <!--                ></ng-container>-->
      <!--              </ul>-->
      <!--            </li>-->
      <!--          </ng-template>-->
      <!--          <li class="border-l">-->
      <!--            <button-->
      <!--              class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"-->
      <!--              (click)="changeParentFolderValue(undefined)"-->
      <!--            >-->
      <!--              /-->
      <!--            </button>-->
      <!--          </li>-->
      <!--          <ng-container-->
      <!--            *ngTemplateOutlet="-->
      <!--              recursiveList;-->
      <!--              context: {-->
      <!--                $implicit: createFolderModalService.availableParentFolderList-->
      <!--              }-->
      <!--            "-->
      <!--          ></ng-container>-->
      <!--        </app-custom-dropdown>-->
      <!--      </ng-container>-->
      <app-form-input
        inputType="text"
        [label]="t('Name')"
        [placeholder]="t('name...')"
        [fc]="nameFormControl"
        [hasError]="nameFormControl.invalid && nameFormControl.touched"
        [errorMessage]="t('required')"
      >
      </app-form-input>
      <div *isSuperAdmin class="flex flex-col my-4">
        <label class="font-semibold text-lg" transloco="School">School</label>
        <select
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 disabled:bg-gray-300"
          formControlName="customer_id"
        >
          <option [value]="customer.id" *ngFor="let customer of allCustomers">
            {{ customer.company }}
          </option>
        </select>
      </div>
      <div
        class="w-full flex items-center justify-between mt-12 gap-4 flex-wrap"
      >
        <button
          class="tw-btn-secondary-outline min-w-[143px]"
          type="button"
          (click)="closeModal()"
          [disabled]="createFolderModalService.createFolderForm.disabled"
          transloco="Cancel"
        >
          Cancel
        </button>
        <button
          class="tw-btn-secondary min-w-[143px]"
          type="button"
          (click)="createFolderModalService.handleSubmit()"
          [disabled]="createFolderModalService.createFolderForm.disabled"
        >
          <span *ngIf="createFolderModalService.isEditing" transloco="Update"
            >Update</span
          >
          <span
            *ngIf="!createFolderModalService.isEditing"
            transloco="Speichern"
            >Speichern</span
          >
        </button>
      </div>
    </div>
  </div>
</app-custom-modal>

<app-custom-modal
  classes="max-w-[444px]"
  [modalEvents]="createFolderModalService.deleteConfirmModalEvents"
  *transloco="let t"
>
  <div header class="flex gap-4 px-9">
    <div class="bg-gray22 rounded w-fit h-fit p-2">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 10V17M10 10L10 17M4 6H20M18 6V17.8C18 18.9201 18.0002 19.4802 17.7822 19.908C17.5905 20.2844 17.2841 20.5902 16.9078 20.782C16.48 21 15.9203 21 14.8002 21H9.2002C8.08009 21 7.51962 21 7.0918 20.782C6.71547 20.5902 6.40973 20.2844 6.21799 19.908C6 19.4802 6 18.9201 6 17.8V6H18ZM16 6H8C8 5.06812 8 4.60216 8.15224 4.23462C8.35523 3.74456 8.74432 3.35523 9.23438 3.15224C9.60192 3 10.0681 3 11 3H13C13.9319 3 14.3978 3 14.7654 3.15224C15.2554 3.35523 15.6447 3.74456 15.8477 4.23462C15.9999 4.60216 16 5.06812 16 6Z"
          stroke="#525A6A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h2 class="text-black font-medium text-28px">Löschen</h2>
  </div>
  <div content-body class="flex flex-col px-9 mt-6 pb-4">
    <p class="text-base text-gray7 font-normal">
      Sind Sie sicher, dass Sie den Ordner
      <span class="font-bold text-primary"
        >"{{ createFolderModalService.folderToDeleteData?.title }}"</span
      >
      löschen möchten?
    </p>
    <div class="w-full flex items-center justify-between mt-12 gap-4 flex-wrap">
      <button
        class="tw-btn-secondary-outline min-w-[143px]"
        type="button"
        (click)="closeDeleteModal()"
        transloco="Cancel"
      >
        Cancel
      </button>
      <button
        class="tw-btn-secondary min-w-[143px]"
        type="button"
        (click)="createFolderModalService.handleFolderDelete()"
      >
        <span transloco="Löschen">Löschen</span>
      </button>
    </div>
  </div>
</app-custom-modal>
