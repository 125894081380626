<div class="mb-10">
  <div class="cursor-pointer" (click)="toggleExpanded()">
    <div class="flex gap-2 items-center">
      <img
        class="duration-300"
        [ngClass]="{'rotate-90': isExpanded, 'w-8 h-8': iconSize === 'normal', 'w-6 h-6': iconSize === 'sm'}"
        src="assets/imgs/icons/bs-chevron-right.svg"
        alt="right arrow"
      />
      <h2 class="font-semibold text-xl leading-7 text-primary text-wrap break-words">{{ title }}</h2>
      <ng-content select=".heading"></ng-content>
    </div>
  </div>
  <div class="expander" [ngClass]="{ expanded: isExpanded }">
    <div class="expander-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
