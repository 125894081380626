<app-custom-modal
  classes="max-w-[1090px]"
  [modalEvents]="slotEditModalService.modalEvents"
  customMaxHeightClass="max-h-[800px]"
  *transloco="let t"
>
  <div content-body class="grid grid-cols-1 lg:grid-cols-2">
    <div class="flex flex-col px-14 pb-10">
      <h1 class="text-black text-28px font-medium mb-10">
        {{ slotEditModalService.currentSlot?.title }}
      </h1>
      <div class="min-w-[325px] mb-10">
        <div class="text-primary text-sm font-medium" transloco="Inhalt">
          Inhalt
        </div>
        <app-custom-dropdown
          [buttonText]="
            getContentNameById(slotEditModalService.contentIdControl.value)
          "
        >
          <ul class="min-w-[325px]">
            <li class="border-l">
              <button
                class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary text-sm font-normal duration-200 w-full"
                (click)="
                  slotEditModalService.contentIdControl.setValue(undefined)
                "
              >
                Fallback
              </button>
            </li>
            <li *ngFor="let content of availableContents" class="border-l">
              <button
                class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary text-sm font-normal duration-200 w-full"
                (click)="
                  slotEditModalService.contentIdControl.setValue(content.id)
                "
              >
                {{ content.title }}
              </button>
            </li>
          </ul>
        </app-custom-dropdown>
      </div>
      <div class="w-full">
        <label class="flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            class="sr-only peer"
            [formControl]="slotEditModalService.enabledControl"
          />
          <div
            class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green7 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green7 peer-checked:after:bg-green5"
          ></div>
          <div class="ml-4 font-medium" transloco="Slot aktiviert">
            Slot aktiviert
          </div>
        </label>
      </div>
      <div class="mt-auto border-t border-gray10 pt-6 flex justify-end gap-6">
        <button
          class="tw-btn-secondary-outline py-14px min-w-[143px]"
          transloco="Abbrechen"
          [disabled]="slotEditModalService.isSubmitting"
          (click)="slotEditModalService.closeModal()"
        >
          Abbrechen
        </button>
        <button
          class="tw-btn-secondary py-14px min-w-[143px]"
          transloco="Speichern"
          [disabled]="slotEditModalService.isSubmitting"
          (click)="slotEditModalService.submitForm()"
        >
          Speichern
        </button>
      </div>
    </div>
    <div class="flex flex-col items-center px-9 overflow-auto">
      <div
        class="relative w-fit"
        [ngStyle]="{
          height: imgParams.containerHeight,
          width: imgParams.containerWidth
        }"
      >
        <img src="/assets/imgs/tv_frame.png" class="w-full h-full" alt="" />
        <div
          *ngIf="selectedContentIsLoading"
          class="absolute flex justify-center items-center animate-pulse"
          [ngStyle]="{
            left: imgParams.left,
            right: imgParams.right,
            top: imgParams.top,
            bottom: imgParams.bottom,
            height: imgParams.height,
            width: imgParams.width
          }"
        >
          <svg
            class="w-20 h-20 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <img
          *ngIf="!selectedContentIsLoading"
          [src]="
            selectedContent?.stored_file?.url ||
            '/storage/default/default_fallback.png'
          "
          class="absolute"
          [ngStyle]="{
            left: imgParams.left,
            right: imgParams.right,
            top: imgParams.top,
            bottom: imgParams.bottom,
            height: imgParams.height,
            width: imgParams.width
          }"
          alt=""
        />
      </div>
    </div>
  </div>
</app-custom-modal>
