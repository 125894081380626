import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../backend-services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersCapabilityGuard
  implements CanActivate, CanActivateChild
{
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.authService.profile.subscribe((profile) => {
      if (
        !profile?.role?.capabilities ||
        !profile.role.capabilities['MANAGE_USERS']
      ) {
        this.router.navigate(['/']);
      }
    });


    return true;
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.authService.profile.subscribe((profile) => {
      if (
        !profile?.role?.capabilities ||
        !profile.role.capabilities['MANAGE_USERS']
      ) {
        this.router.navigate(['/']);
      }
    });


    return true;
  }
}
