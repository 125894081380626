import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformName'
})
export class TransformNamePipe implements PipeTransform {

  transform(value: {salutation: string, first_name: string, last_name: string}): string {
    return `${value.salutation} ${value.first_name} ${value.last_name}`;
  }

}
