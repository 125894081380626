import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  units = ['bytes', 'KB', 'MB', 'GB', 'TB'];

  transform(bytes: number = 0, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // Ensure i (the unit index) stays within 0-4
    const limitedIndex = Math.min(i, 4);

    return `${parseFloat((bytes / Math.pow(k, limitedIndex)).toFixed(dm))} ${this.units[limitedIndex]}`;
  }

}
