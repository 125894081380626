import { Component, ElementRef, ViewChild } from '@angular/core';
import { CreateFolderModalService } from './create-folder-modal.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { RequestFolderService } from '../../backend-services/request-folder.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-folder-modal',
  templateUrl: './create-folder-modal.component.html',
  styleUrls: ['./create-folder-modal.component.scss'],
})
export class CreateFolderModalComponent {
  @ViewChild('requestFolderToggleConfirmDeleteModalBtn')
  requestFolderToggleConfirmDeleteModalBtn: ElementRef | undefined;

  allCustomers: Customer[] = [];
  currentFolderId?: number = undefined;

  constructor(
    public createFolderModalService: CreateFolderModalService,
    private customerService: CustomerService,
    private authService: AuthService,
    public requestFolderService: RequestFolderService,
    private toastr: ToastrService
  ) {
    this.requestFolderService.getRequestFolders();

    this.authService.isSuperAdmin.subscribe((isSuperAdmin) => {
      if (isSuperAdmin) {
        // fetch customers if user is SuperAdmin
        this.customerService.getCustomers().subscribe({
          next: (res) => {
            if (res.success) {
              this.allCustomers = res.data;
            }
          },
          error: (err) => console.log(err),
        });
      }
    });
  }

  get nameFormControl() {
    return this.createFolderModalService.createFolderForm.get(
      'title'
    ) as FormControl;
  }

  closeModal() {
    this.createFolderModalService.modalEvents.next({ type: 'close' });
  }

  closeDeleteModal() {
    this.createFolderModalService.deleteConfirmModalEvents.next({
      type: 'close',
    });
  }

  changeParentFolderValue(newValue?: number) {
    this.createFolderModalService.parentIdControl.setValue(newValue);
  }
}
