import { Component } from '@angular/core';
import { AuthService } from '../../../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  constructor(
    public authService: AuthService,
    private translocoService: TranslocoService
  ) {}

  isActive(url: string, full: boolean = false): boolean {
    if (full) {
      return location.pathname === url;
    } else {
      return location.pathname.startsWith(url);
    }
  }

  logout() {
    this.authService.logout();
  }

  get activeLang() {
    return this.translocoService.getActiveLang();
  }

  setLanguage(lang: string) {
    localStorage.setItem('current_language', lang);
    this.translocoService.setActiveLang(lang);
  }
}
