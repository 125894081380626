import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export type PaginationParams = {
  page: number;
  limit: number;
  sortBy?: string;
  sortDir?: string;
};

const defaultParams: PaginationParams = {
  page: 1,
  limit: 10,
};

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() total: number = 0;
  queryParams = { ...defaultParams };
  paginationData?: {
    endSection: any[];
    havePostMidData: boolean;
    middleSection: any[];
    havePreMidData: boolean;
    startSection: number[];
  } = undefined;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      // add default params if not there
      this.queryParams.page =
        // @ts-ignore
        parseInt(queryParams['page']) || this.queryParams.page;
      this.queryParams.limit =
        // @ts-ignore
        parseInt(queryParams['limit']) || this.queryParams.limit;

      this.generatePaginationData();

      this.handleQueryParamChange();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['total']) {
      this.generatePaginationData();
    }
  }

  generatePaginationData() {
    const startSection = [1];
    const endSection = [];
    const middleSection = [];
    let havePreMidData = false;
    let havePostMidData = false;

    const totalPages = Math.ceil(this.total / this.queryParams.limit);
    const currentPage = this.queryParams.page;

    // start section
    if (totalPages >= 2) {
      startSection.push(2);
    }

    // end section
    if (totalPages >= 3) {
      endSection.push(totalPages);
    }
    if (totalPages >= 4) {
      endSection.unshift(totalPages - 1);
    }

    // middle section
    if (totalPages >= 5) {
      const middleElementCount = Math.min(3, totalPages - 4);
      let cursorPosition = currentPage;

      if (currentPage <= 2) {
        cursorPosition = 3;
      } else if (currentPage >= totalPages - 1) {
        cursorPosition = totalPages - 1 - middleElementCount;
      } else if (currentPage > 3) {
        cursorPosition = currentPage - 1;
      }

      havePreMidData = middleElementCount > 0 && cursorPosition > 3;
      havePostMidData =
        middleElementCount > 0 &&
        cursorPosition + middleElementCount < totalPages - 1;

      for (let i = 1; i <= middleElementCount; i++) {
        middleSection.push(cursorPosition);
        cursorPosition++;
      }
    }

    this.paginationData = {
      startSection,
      middleSection,
      endSection,
      havePreMidData,
      havePostMidData,
    };
  }

  changePage(type: 'first' | 'previous' | 'next' | 'last') {
    const lastPage = Math.ceil(this.total / this.queryParams.limit);

    switch (type) {
      case 'first':
        this.queryParams.page = 1;
        break;
      case 'previous':
        this.queryParams.page = Math.max(this.queryParams.page - 1, 1);
        break;
      case 'next':
        this.queryParams.page = Math.min(+this.queryParams.page + 1, lastPage);
        break;
      case 'last':
        this.queryParams.page = lastPage;
        break;
    }

    this.handleQueryParamChange();
  }

  changePageNumber(n: number) {
    this.queryParams.page = n;
    this.handleQueryParamChange();
  }

  isLastPage() {
    return (
      this.queryParams.page === Math.ceil(this.total / this.queryParams.limit)
    );
  }

  paginationMessage() {
    const start = (this.queryParams.page - 1) * this.queryParams.limit + 1;
    const end = Math.min(start + +this.queryParams.limit - 1, this.total);
    return `${start}-${end} von ${this.total}`;
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
