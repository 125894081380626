import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AuthService } from '../backend-services/auth.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isNotSuperAdmin]',
})
export class IsNotSuperAdminDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    let renderedPositiveState = false;
    let renderedNegativeState = false;

    this.authService.isSuperAdmin.subscribe({
      next: (isSuperAdmin) => {
        if (!isSuperAdmin) {
          if (!renderedNegativeState) {
            renderedNegativeState = true;
            renderedPositiveState = false;
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        } else {
          if (!renderedPositiveState) {
            renderedNegativeState = false;
            renderedPositiveState = true;

            this.viewContainer.clear();
          }
        }
      },
    });
  }
}
