import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from '../../backend-services/auth.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss'],
})
export class ProfilePictureComponent implements OnInit, OnChanges {
  nameString = '';

  @Input() firstName?: string;
  @Input() lastName?: string;
  @Input() is_custom: boolean = false;
  @Input() rounded: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.firstName || this.lastName || this.is_custom) {
      this.formartNameString(this.firstName ?? '', this.lastName ?? '');
    } else {
      this.authService.profile.subscribe((profile) => {
        if (profile) {
          this.formartNameString(profile.first_name, profile.last_name);
        }
      });
    }
  }

  formartNameString(firstName: string, lastName: string) {
    this.nameString = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.formartNameString(this.firstName ?? '', this.lastName ?? '');
  }

  get backgroundColor() {
    return this.generateColorPair(`${this.firstName}${this.lastName}`).backgroundColor;
  }

  get foregroundColor() {
    return this.generateColorPair(`${this.firstName}${this.lastName}`).foregroundColor;
  }

  generateColorPair(name: string) {
    // Hash the name to get a numeric value
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to an RGB color
    let r = (hash >> 16) & 0xFF;
    let g = (hash >> 8) & 0xFF;
    let b = hash & 0xFF;
    let backgroundColor = `rgb(${r}, ${g}, ${b})`;

    // Calculate the brightness of the background color
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Determine foreground color based on brightness
    let foregroundColor = brightness > 128 ? 'black' : 'white';

    return {
      backgroundColor,
      foregroundColor
    };
  }
}
