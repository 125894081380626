import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type AppContent = {
  id: number;
  title: string;
  data?: string;
  created_at: string;
  updated_at: string;
  stored_file_id: number;
  customer_id: number;
  request_folder_id?: number;
  enabled: boolean;
  stored_file?: ContentFile;
};

export type ContentFile = {
  id: number;
  customer_id: number;
  file_size: number;
  file_type_id?: number;
  created_at: string;
  updated_at: string;
  url: string;
  key: string;
};

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private http: HttpClient) {}

  getContentById(id: number, query?: any) {
    return this.http.get<{ success: boolean; data: AppContent }>(
      `/api/inhalte/${id}`,
      { params: query }
    );
  }

  deleteContent(id: number) {
    return this.http.delete<{ success: true }>(`/api/inhalte/${id}`);
  }

  getAllContents() {
    return this.http.get<{ success: boolean; data: AppContent[] }>(
      `/api/inhalte`
    );
  }
}
