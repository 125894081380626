import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.email]),
  });
  serverErrorMessage?: string = undefined;
  formIsSubmitting = false;
  success = false;
  modalEvents = new Subject<ModalEvents>();

  get emailControl() {
    return this.forgotPasswordForm.get('email') as AbstractControl;
  }

  constructor(
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
      }
    });
  }

  onSubmit() {
    this.forgotPasswordForm.markAllAsTouched();
    this.serverErrorMessage = undefined;
    this.success = false;

    if (this.forgotPasswordForm.valid) {
      this.formIsSubmitting = true;
      this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe({
        error: (res) => {
          if (res.error?.error === 'NOT_FOUND') {
            this.serverErrorMessage = this.translocoService.translate(
              `Die E-Mail wurde nicht gefunden`
            );
          } else {
            this.serverErrorMessage =
              this.translocoService.translate(`Something went wrong`);
          }

          this.formIsSubmitting = false;
        },
        next: () => {
          this.formIsSubmitting = false;
          this.success = true;
          this.modalEvents.next({ type: 'open' });
        },
      });
    }
  }

  resend() {
    this.modalEvents.next({ type: 'close' });
    this.onSubmit();
  }
}
