import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {AuthService, UserCapabilities} from '../backend-services/auth.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasCapability]',
})
export class HasCapabilityDirective implements OnInit {
  @Input() hasCapability?: keyof UserCapabilities;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.profile.subscribe({
      next: (profile) => {
        if (
          this.hasCapability &&
          profile?.role?.capabilities &&
          profile.role.capabilities[this.hasCapability]
        ) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      },
    });
  }
}
