<app-custom-modal
  classes="max-w-[644px]"
  [modalEvents]="contentPreviewModalService.modalEvents"
  customMaxHeightClass="max-h-[940px]"
  *transloco="let t"
>
  <div header class="flex flex-col gap-2 px-9">
    <h2 class="text-black font-medium text-28px" transloco="Vorschau">
      Vorschau
    </h2>
    <h3 class="text-base text-black">
      {{ contentPreviewModalService.currentContent?.title }}
    </h3>
  </div>
  <div content-body class="flex flex-col items-center px-9 mt-6 overflow-auto">
    <div
      class="relative w-full"
      [ngStyle]="{
        height: imgParams.containerHeight,
        width: imgParams.containerWidth
      }"
    >
      <img src="/assets/imgs/tv_frame.png" alt="" class="w-full h-full" />
      <img
        [src]="contentPreviewModalService.currentContent?.stored_file?.url"
        class="absolute"
        [ngStyle]="{
          left: imgParams.left,
          right: imgParams.right,
          top: imgParams.top,
          bottom: imgParams.bottom,
          height: imgParams.height,
          width: imgParams.width
        }"
        alt="preview"
      />
    </div></div
></app-custom-modal>
