import { Component, Input } from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-status-display',
  templateUrl: './status-display.component.html',
  styleUrls: ['./status-display.component.scss'],
})
export class StatusDisplayComponent {
  @Input() statusId!: number;
  @Input() variant: number = 1;

  statusMap = new Map([
    [1, { text: this.translocoService.translate(`Warten auf Upload`), icon: 'hour-glass.svg', color: 'gray7' }],
    [101, { text: this.translocoService.translate(`Bereit für Versand`),  icon: 'pending-submit.svg', color: 'orange1' }],
    [102, { text: this.translocoService.translate(`Daten Fehler`), icon: 'bs-exclamation.svg', color: 'red3' }],
    [2, { text: this.translocoService.translate(`Warten auf Agentur`), icon: 'hour-glass.svg', color: 'light-blue2' }],
    [202, { text: this.translocoService.translate(`Abgelehnt d. Agentur`), icon: 'bs-exclamation.svg', color: 'red3' }],
    [203, { text: this.translocoService.translate(`Bürstenabzug`), icon: 'bs-file-earmark-break.svg', color: 'orange1' }],
    [204, { text: this.translocoService.translate(`Abgelehnt d. Kunde`), icon: 'bs-exclamation.svg', color: 'red3' }],
    [205, { text: this.translocoService.translate(`Freigabe`), icon: 'bs-path-check.svg', color: 'green3' }],
    [3, { text: this.translocoService.translate(`Warten auf Produzent`), icon: 'hour-glass.svg', color: 'light-blue4' }],
    [302, { text: this.translocoService.translate(`Abgelehnt d. Produzent`), icon: 'bs-exclamation.svg', color: 'red3' }],
    [303, { text: this.translocoService.translate(`Bürstenabzug`), icon: 'bs-file-earmark-break.svg', color: 'orange1' }],
    [304, { text: this.translocoService.translate(`Abgelehnt d. Kunde`), icon: 'bs-exclamation.svg', color: 'red3' }],
    [305, { text: this.translocoService.translate(`Freigabe`), icon: 'bs-path-check.svg', color: 'green3' }],
    [4, { text: this.translocoService.translate(`Freigabe Agentur`), icon: 'bs-path-check.svg', color: 'green2' }],
    [401, { text: this.translocoService.translate(`Freigabe Produzent`), icon: 'bs-path-check.svg', color: 'green4' }],
  ]);

  constructor(private translocoService: TranslocoService) {}

  get statusText() {
    return this.statusMap.get(this.statusId || 1)!.text;
  }

  get statusIcon() {
    return this.statusMap.get(this.statusId || 1)!.icon;
  }

  get bgColor() {
    return `bg-${this.statusMap.get(this.statusId || 1)!.color}`;
  }

  get textColor() {
    return `text-${this.statusMap.get(this.statusId || 1)!.color}`;
  }
}
