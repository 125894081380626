import { Component, Input, OnInit } from '@angular/core';
import { CustomerService } from '../../backend-services/customer.service';

@Component({
  selector: 'app-company-name-display',
  templateUrl: './company-name-display.component.html',
  styleUrls: ['./company-name-display.component.scss'],
})
export class CompanyNameDisplayComponent implements OnInit {
  @Input() customerId?: number;
  companyName = '';

  constructor(private customerService: CustomerService) {}

  ngOnInit(): void {
    if (this.customerId) {
      this.customerService.getCustomerById(this.customerId).subscribe({
        next: (res) => {
          if (res) {
            this.companyName = res.company ?? '';
          }
        },
        error: (err) => {
          this.companyName = err;
        },
      });
    }
  }
}
