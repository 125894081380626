import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  formGroup = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        ),
      ]),
      confirmPassword: new FormControl('', []),
    },
    { validators: matchPasswords }
  );
  token = '';
  email = '';
  serverErrorMessage?: string = undefined;
  formIsSubmitting = false;
  success = false;
  modalEvents = new Subject<ModalEvents>();

  @ViewChild('passwordElement') passwordElement: ElementRef | undefined;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService
  ) {
    this.passwordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });

    this.route.queryParamMap.subscribe((params) => {
      const token = params.get('token');
      const email = params.get('email');

      if (!token || !email) {
        this.router.navigate(['/']);
      } else {
        this.token = token;
        this.email = email;
      }
    });

    // redirect to dashboard if logged in
    this.authService.isAuthenticated.subscribe((res) => {
      if (res) {
        this.router.navigate(['/']);
      }
    });

    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
      }
    });
  }

  get passwordControl() {
    return this.formGroup.get('password') as AbstractControl;
  }

  get confirmPasswordControl() {
    return this.formGroup.get('confirmPassword') as AbstractControl;
  }

  onPasswordChange() {
    if (this.passwordControl.valid) {
      this.passwordElement?.nativeElement.setCustomValidity('');
    } else {
      this.passwordElement?.nativeElement.setCustomValidity('invalid');
    }
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.serverErrorMessage = undefined;
    this.success = false;

    if (this.formGroup.valid) {
      this.formIsSubmitting = true;
      this.formGroup.disable();
      this.authService
        .resetPasswordWithToken({
          new_password: this.passwordControl.value,
          token: this.token,
        })
        .subscribe({
          error: (res) => {
            console.log(res.error);
            if (res.error?.error === 'NOT_FOUND') {
              this.serverErrorMessage = res.error?.message;
            } else {
              this.serverErrorMessage =
                this.translocoService.translate(`Something went wrong`);
            }

            this.formIsSubmitting = false;
            this.formGroup.enable();
          },
          next: (res) => {
            if (res.success) {
              this.modalEvents.next({ type: 'open' });
            }

            this.formIsSubmitting = false;
            this.formGroup.enable();
            this.success = true;
          },
        });
    }
  }
}

function matchPasswords(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const password = control.get('password')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;

  return password !== confirmPassword ? { passwordMismatch: true } : null;
}
