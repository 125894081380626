import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsSuperAdminDirective } from './is-super-admin.directive';
import { HasCapabilityDirective } from './has-capability.directive';
import { IsNotSuperAdminDirective } from './is-not-super-admin.directive';



@NgModule({
  declarations: [
    IsSuperAdminDirective,
    HasCapabilityDirective,
    IsNotSuperAdminDirective,
  ],
  exports: [
    IsSuperAdminDirective,
    HasCapabilityDirective,
    IsNotSuperAdminDirective,
  ],
  imports: [CommonModule],
})
export class DirectivesModule {}
