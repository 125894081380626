import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../backend-services/auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  personalDataForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl('', [Validators.email]),
  });
  personalDataFormError?: string = undefined;
  personalDataFormIsSubmitting = false;

  lastUpdatedAt?: string;

  modalEvents = new Subject<ModalEvents>();

  get firstNameControl() {
    return this.personalDataForm.get('first_name') as AbstractControl;
  }
  get lastNameControl() {
    return this.personalDataForm.get('last_name') as AbstractControl;
  }
  get emailControl() {
    return this.personalDataForm.get('email') as AbstractControl;
  }


  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private _location: Location
  ) {
    authService.profile.subscribe((profile) => {
      this.personalDataForm.get('first_name')?.setValue(profile?.first_name);
      this.personalDataForm.get('last_name')?.setValue(profile?.last_name);
      this.personalDataForm.get('email')?.setValue(profile?.email);
      this.lastUpdatedAt = profile?.updated_at;
    });
  }

  saveProfileData() {
    this.personalDataForm.markAllAsTouched();
    this.personalDataFormError = undefined;

    if (this.personalDataForm.valid) {
      this.personalDataFormIsSubmitting = true;
      this.personalDataForm.disable();

      this.authService.updateProfile(this.personalDataForm.value).subscribe({
        next: () => {
          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
          this.toastr.success(
            this.translocoService.translate(`Ihr Profil wurde gespeichert`)
          );
        },
        error: (res) => {
          this.personalDataFormError = res.error?.message;
          this.toastr.error(
            typeof res.error?.message === 'string'
              ? res.error.message
              : res.error.error
          );

          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
        },
      });
    }
  }

  goBack() {
    this._location.back();
  }

  openPasswordChangeModal() {
    this.modalEvents.next({ type: 'open' });
  }
}
