import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { initFlowbite } from 'flowbite';

export type ModalEvents =
  | { type: 'open' }
  | { type: 'init-close' }
  | { type: 'close' };

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
})
export class CustomModalComponent implements AfterViewInit {
  @Input() modalEvents!: Subject<ModalEvents | any>;
  @Input() classes?: string;
  @Input() customMaxHeightClass?: string;

  @ViewChild('toggleCustomModalBtn') toggleModalBtn: ElementRef | undefined;
  @ViewChild('showCustomModalBtn') showModalBtn: ElementRef | undefined;
  @ViewChild('hideCustomModalBtn') hideModalBtn: ElementRef | undefined;

  componentId: string;

  constructor() {
    this.componentId = `${Math.floor(Math.random() * 100000)}-${Date.now()}}`;
  }

  ngAfterViewInit(): void {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'open':
          this.showModalBtn?.nativeElement.click();
          break;
        case 'close':
          this.hideModalBtn?.nativeElement.click();
          break;
      }
    });

    initFlowbite();
  }

  initClose() {
    this.modalEvents.next({ type: 'init-close' });
  }
}
