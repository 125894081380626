<div
  class="flex container mx-auto"
  *transloco="let t"
  [formGroup]="customerForm"
>
  <div class="w-full">
    <button
      (click)="goBack()"
      class="flex items-center gap-2 font-medium text-sm text-gray7"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8333 14.6667L9.16666 11L12.8333 7.33337"
          stroke="#828B9C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ t("Zurück") }}
    </button>
    <div class="py-5">
      <h1
        transloco="Neue Schule anlegen"
        class="text-20px font-bold"
        *ngIf="!id"
      >
        Neue Schule anlegen
      </h1>
      <h1 transloco="Schule bearbeiten" class="text-20px font-bold" *ngIf="id">
        Schule bearbeiten
      </h1>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          01. <span transloco="Allgemein">Allgemein</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Name')"
              [placeholder]="t('Name') + '...'"
              [fc]="companyControl"
              [hasError]="companyControl.invalid && companyControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-4">
            <app-form-input
              [label]="t('Straße')"
              [placeholder]="t('Straße') + '...'"
              [fc]="billingStreetControl"
              [hasError]="
                billingStreetControl.invalid && billingStreetControl.touched
              "
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-2">
            <app-form-input
              [label]="t('Nr')"
              [placeholder]="t('Enter Nr') + '...'"
              [fc]="billingHouseNumberControl"
              [hasError]="
                billingHouseNumberControl.invalid &&
                billingHouseNumberControl.touched
              "
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-2">
            <app-form-input
              [label]="t('PLZ')"
              [placeholder]="t('PLZ') + '...'"
              [fc]="billingZipControl"
              [hasError]="
                billingZipControl.invalid && billingZipControl.touched
              "
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-4">
            <app-form-input
              [label]="t('Ort')"
              [placeholder]="t('Ort') + '...'"
              [fc]="billingCityControl"
              [hasError]="
                billingCityControl.invalid && billingCityControl.touched
              "
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Telefon')"
              [placeholder]="t('Telefon') + '...'"
              [fc]="phoneControl"
              [hasError]="phoneControl.invalid && phoneControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
        </div>
        <div class="mt-8">
          <label class="flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              class="sr-only peer"
              [formControl]="enabledControl"
            />
            <div
              class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green7 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green7 peer-checked:after:bg-green5"
            ></div>
            <div
              class="ms-3 text-lg font-medium text-primary"
              transloco="Schule Aktiviert"
            >
              Schule Aktiviert
            </div>
          </label>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          02. <span transloco="Ansprechpartner">Ansprechpartner</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Vorname')"
              [placeholder]="t('Vorname') + '...'"
              [fc]="firstNameControl"
              [hasError]="firstNameControl.invalid && firstNameControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Nachname')"
              [placeholder]="t('Nachname') + '...'"
              [fc]="lastNameControl"
              [hasError]="lastNameControl.invalid && lastNameControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('E-Mail-Adresse')"
              [placeholder]="t('E-Mail-Adresse') + '...'"
              [fc]="billingEmailControl"
              [hasError]="
                billingEmailControl.invalid && billingEmailControl.touched
              "
              [errorMessage]="t('Ungültige E-Mail')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3"></div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Telefon')"
              [placeholder]="t('Telefon') + '...'"
              [fc]="billingPhoneControl"
              [hasError]="
                billingPhoneControl.invalid && billingPhoneControl.touched
              "
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3"></div>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          03. <span transloco="Slots">Slots</span>
        </h2>
      </div>
      <div class="lg:col-span-3 flex flex-col gap-6" formArrayName="slots">
        <div
          [formGroupName]="i"
          *ngFor="let slot of slotArray.controls; let i = index"
        >
          <div class="text-primary font-medium text-sm">Slot #{{ i + 1 }}</div>
          <div class="mt-2 flex flex-wrap gap-x-2 items-end">
            <div>
              <label class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  formControlName="enabled"
                />
                <div
                  class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green7 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green7 peer-checked:after:bg-green5"
                ></div>
              </label>
            </div>
            <div *ngIf="!availableContents" class="animate-pulse w-[325px] h-[46px] bg-gray-200 rounded-xl">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="min-w-[325px] ml-2" *ngIf="availableContents">
              <app-custom-dropdown
                [buttonText]="getContentNameById(slot.get('content_id')?.value)"
              >
                <ul>
                  <li class="border-l">
                    <button
                      class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary text-sm font-normal duration-200 w-full"
                      (click)="slot.get('content_id')?.setValue(undefined)"
                    >
                      Fallback
                    </button>
                  </li>
                  <li
                    *ngFor="let content of availableContents"
                    class="border-l"
                  >
                    <button
                      class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary text-sm font-normal duration-200 w-full"
                      (click)="slot.get('content_id')?.setValue(content.id)"
                    >
                      {{ content.title }}
                    </button>
                  </li>
                </ul>
              </app-custom-dropdown>
            </div>
            <button
              class="tw-btn-ghost-outline flex gap-2 px-3 py-3 font-medium text-base"
              (click)="copySlotUrl(slot.get('public_hash')?.value)"
            >
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.40741 14.0933L13.5929 8.9078M6.46322 10.8523L5.16685 12.1486C3.73493 13.5805 3.73465 15.9021 5.16658 17.334C6.5985 18.766 8.92038 18.766 10.3523 17.334L11.6481 16.0377M10.3517 6.96305L11.6481 5.66669C13.08 4.23476 15.4015 4.23482 16.8334 5.66674C18.2653 7.09867 18.2657 9.42021 16.8338 10.8521L15.5375 12.1485"
                  stroke="#292D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span transloco="Url kopieren">Url kopieren</span>
            </button>
            <button
              class="tw-btn-ghost-outline flex gap-2 px-3 py-3 font-medium text-base"
              (click)="slotArray.removeAt(i)"
            >
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.8332 9.66667V16.0833M9.1665 9.66667L9.1665 16.0833M3.6665 6H18.3332M16.4998 6V16.8167C16.4998 17.8434 16.5 18.3569 16.3002 18.749C16.1244 19.094 15.8436 19.3744 15.4986 19.5502C15.1065 19.75 14.5934 19.75 13.5667 19.75H8.43335C7.40659 19.75 6.89282 19.75 6.50065 19.5502C6.15569 19.3744 5.87543 19.094 5.69966 18.749C5.49984 18.3569 5.49984 17.8434 5.49984 16.8167V6H16.4998ZM14.6665 6H7.33317C7.33317 5.14577 7.33317 4.71865 7.47272 4.38173C7.6588 3.93252 8.01546 3.57563 8.46468 3.38955C8.80159 3.25 9.22894 3.25 10.0832 3.25H11.9165C12.7707 3.25 13.1978 3.25 13.5348 3.38955C13.984 3.57563 14.3408 3.93252 14.5269 4.38173C14.6664 4.71865 14.6665 5.14578 14.6665 6Z"
                  stroke="#292D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span transloco="Löschen">Löschen</span>
            </button>
          </div>
        </div>
        <button
          class="tw-btn-ghost w-fit flex gap-2 px-3 py-3 font-medium text-base text-orange1"
          (click)="addNewSlot()"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 11H16.5M11 16.5L11 5.5"
              stroke="#FF6600"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span transloco="Slot hinzufügen">Slot hinzufügen</span>
        </button>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-6">
      <button
        transloco="Abbrechen"
        class="tw-btn-secondary-outline min-w-[140px]"
        (click)="goBack()"
        [disabled]="customerFormLoading"
      >
        Abbrechen
      </button>
      <button
        transloco="Speichern"
        class="tw-btn-secondary min-w-[140px]"
        (click)="handleSubmit()"
        [disabled]="customerFormLoading"
      >
        Speichern
      </button>
    </div>
  </div>
</div>
