<div
  class="grid grid-cols-1 lg:grid-cols-2 min-h-screen max-w-[1440px] mx-auto"
  *transloco="let t"
>
  <div class="flex justify-center items-center">
    <form
      action="#"
      novalidate
      [formGroup]="forgotPasswordForm"
      (ngSubmit)="onSubmit()"
      class="flex flex-col max-w-[310px]"
    >
      <img
        src="assets/imgs/school-logo.svg"
        alt="logo"
        class="w-[156px] mb-6"
      />
      <h1
        class="text-black text-3xl font-medium leading-43.2px mb-1"
        transloco="Passwort vergessen"
      >
        Passwort vergessen
      </h1>
      <p
        class="text-gray7 leading-6 tracking-0.08px mb-4"
        transloco="Um Ihr Passwort zurückzusetzen, geben Sie Ihre E-Mail-Adresse ein."
      >
        Um Ihr Passwort zurückzusetzen, geben Sie Ihre E-Mail-Adresse ein.
      </p>
      <div
        class="px-3 px2 text-white bg-semantic-danger-500 rounded-lg my-2"
        role="alert"
        *ngIf="serverErrorMessage"
      >
        {{ serverErrorMessage }}
      </div>
      <app-form-input
        [label]="t('E-Mail-Adresse')"
        [placeholder]="t('E-Mail-Adresse...')"
        [fc]="emailControl"
        [hasError]="emailControl.invalid && emailControl.touched"
        [errorMessage]="t('Ungültige E-Mail')"
        class="mb-10 max-w-[320p]"
      ></app-form-input>
      <button
        class="tw-btn-secondary-v2"
        type="submit"
        [disabled]="formIsSubmitting"
        transloco="Passwort zurücksetzen"
      >
        Passwort zurücksetzen
      </button>
      <a class="text-orange1 flex items-center justify-center" href="/login">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.16659 9.33341L5.83325 7.00008L8.16659 4.66675"
            stroke="#FF6600"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        {{ t("Zurück zum Login") }}
      </a>
    </form>
  </div>
  <div class="hidden lg:flex p-6 justify-center items-center">
    <img
      src="assets/imgs/auth_bg.webp"
      alt=""
      class="object-cover w-full rounded-2xl max-h-[800px]"
    />
  </div>
</div>
<app-custom-modal
  classes="max-w-3xl"
  [modalEvents]="modalEvents"
  *transloco="let t"
>
  <div content-body class="flex justify-center">
    <div
      class="flex flex-col w-full justify-center items-center text-center max-w-[450px]"
    >
      <svg
        width="124"
        height="124"
        viewBox="0 0 124 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.5" cx="62" cy="62" r="62" fill="#CC00A0" />
        <circle cx="62" cy="62" r="42" fill="#CC00A0" />
        <path
          d="M73.6663 55.333C72.2775 55.333 71.0969 54.8469 70.1247 53.8747C69.1525 52.9025 68.6663 51.7219 68.6663 50.333C68.6663 48.9441 69.1525 47.7636 70.1247 46.7913C71.0969 45.8191 72.2775 45.333 73.6663 45.333C75.0552 45.333 76.2358 45.8191 77.208 46.7913C78.1802 47.7636 78.6663 48.9441 78.6663 50.333C78.6663 51.7219 78.1802 52.9025 77.208 53.8747C76.2358 54.8469 75.0552 55.333 73.6663 55.333ZM48.6663 75.333C47.7497 75.333 46.9647 75.0063 46.3113 74.353C45.658 73.6997 45.3319 72.9152 45.333 71.9997V51.9997C45.333 51.083 45.6597 50.298 46.313 49.6447C46.9663 48.9913 47.7508 48.6652 48.6663 48.6663H65.4997C65.3886 49.2219 65.333 49.7775 65.333 50.333C65.333 50.8886 65.3886 51.4441 65.4997 51.9997C65.6941 52.8886 66.0136 53.7152 66.458 54.4797C66.9025 55.2441 67.4441 55.9175 68.083 56.4997L61.9997 60.333L48.6663 51.9997V55.333L61.9997 63.6663L70.7913 58.1663C71.2636 58.333 71.7358 58.458 72.208 58.5413C72.6802 58.6247 73.1663 58.6663 73.6663 58.6663C74.5552 58.6663 75.4302 58.5275 76.2913 58.2497C77.1525 57.9719 77.9441 57.5552 78.6663 56.9997V71.9997C78.6663 72.9163 78.3397 73.7013 77.6863 74.3547C77.033 75.008 76.2486 75.3341 75.333 75.333H48.6663Z"
          fill="#FFF8FF"
        />
      </svg>
      <h1
        class="text-black text-3xl font-medium leading-43.2px mt-6"
        transloco="Überprüfen Sie Ihre E-Mails"
      >
        Überprüfen Sie Ihre E-Mails
      </h1>
      <p class="text-gray7 leading-6 tracking-0.08px mt-2">
        {{ t("Wir haben einen Link zum Zurücksetzen des Passworts an ")
        }}<span class="text-blue-500">{{ emailControl.value }}</span>
        {{ t("gesendet") }}
      </p>
      <div class="text-gray7 leading-6 tracking-0.08px mt-10 mb-20">
        {{
          t(
            "Sie haben die E-Mail zum Zurücksetzen des Passworts nicht erhalten? Klicken Sie auf"
          )
        }}
        <button class="text-orange1" (click)="resend()">Erneut senden</button>
      </div>
    </div>
  </div>
</app-custom-modal>
