import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { Slot, SlotService } from '../../backend-services/slot.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class SlotEditModalService {
  modalEvents = new Subject<ModalEvents | { type: 'init-open'; slot: Slot }>();
  currentSlot?: Slot;

  slotForm = new FormGroup({
    enabled: new FormControl(true),
    content_id: new FormControl(undefined),
  });
  isSubmitting = false;

  get enabledControl() {
    return this.slotForm.get('enabled') as FormControl;
  }

  get contentIdControl() {
    return this.slotForm.get('content_id') as FormControl;
  }

  constructor(
    private slotService: SlotService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
          break;
        case 'init-open':
          this.currentSlot = ev.slot;
          this.modalEvents.next({ type: 'open' });
          this.enabledControl.setValue(ev.slot.enabled);
          this.contentIdControl.setValue(ev.slot.content_id);
          break;
        case 'close':
          this.currentSlot = undefined;
      }
    });
  }

  closeModal() {
    this.modalEvents.next({ type: 'close' });
  }

  submitForm() {
    if (this.currentSlot) {
      this.isSubmitting = true;
      this.slotService
        .updateSlot(this.currentSlot!.id, this.slotForm.value)
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translocoService.translate('Slot updated')
            );
            this.closeModal();
            this.isSubmitting = false;
          },
          error: (err) => {
            console.log(err);
            this.toastrService.error(
              this.translocoService.translate('Something went wrong')
            );
            this.isSubmitting = false;
          },
        });
    }
  }
}
