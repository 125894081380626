import { Component, OnInit } from '@angular/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-content-management-layout',
  templateUrl: './content-management-layout.component.html',
  styleUrls: ['./content-management-layout.component.scss'],
})
export class ContentManagementLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    initFlowbite();
  }
}
