<nav
  class="flex items-center justify-center w-full p-8 pt-16"
  aria-label="Table navigation"
>
  <div *ngIf="paginationData" class="flex gap-6 text-gray4 font-medium">
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center text-darkBlue2 disabled:text-gray4"
      (click)="changePage('previous')"
      [disabled]="queryParams.page === 1"
    >
      <svg
        width="9"
        height="12"
        viewBox="0 0 9 12"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.16016 1.41L3.58016 6L8.16016 10.59L6.75016 12L0.750156 6L6.75016 0L8.16016 1.41Z"
        />
      </svg>
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center disabled:text-darkBlue2 disabled:shadow-inner disabled:font-bold"
      (click)="changePageNumber(page)"
      [disabled]="queryParams.page === page"
      *ngFor="let page of paginationData.startSection"
    >
      {{ page }}
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center disabled:text-darkBlue2 disabled:shadow-inner disabled:font-bold"
      [disabled]="true"
      *ngIf="paginationData.havePreMidData"
    >
      ...
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center disabled:text-darkBlue2 disabled:shadow-inner disabled:font-bold"
      (click)="changePageNumber(page)"
      [disabled]="queryParams.page === page"
      *ngFor="let page of paginationData.middleSection"
    >
      {{ page }}
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center disabled:text-darkBlue2 disabled:shadow-inner disabled:font-bold"
      [disabled]="true"
      *ngIf="paginationData.havePostMidData"
    >
      ...
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center disabled:text-darkBlue2 disabled:shadow-inner disabled:font-bold"
      (click)="changePageNumber(page)"
      [disabled]="queryParams.page === page"
      *ngFor="let page of paginationData.endSection"
    >
      {{ page }}
    </button>
    <button
      class="w-8 h-8 rounded-l border border-gray3 flex justify-center items-center text-darkBlue2 disabled:text-gray4"
      (click)="changePage('next')"
      [disabled]="isLastPage()"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.83984 7.41L13.4198 12L8.83984 16.59L10.2498 18L16.2498 12L10.2498 6L8.83984 7.41Z"
        />
      </svg>
    </button>
  </div>
</nav>
