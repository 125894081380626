<div class="flex container mx-auto" *transloco="let t">
  <div class="w-full">
    <button
      (click)="goBack()"
      class="flex items-center gap-2 font-medium text-sm text-gray7"
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8333 14.6667L9.16666 11L12.8333 7.33337"
          stroke="#828B9C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ t("Zurück") }}
    </button>
    <div class="py-5">
      <h1
        transloco="Neuen Benutzer anlegen"
        class="text-20px font-bold"
        *ngIf="!id"
      >
        Neuen Benutzer anlegen
      </h1>
      <h1
        transloco="Benutzer bearbeiten"
        class="text-20px font-bold"
        *ngIf="id"
      >
        Benutzer bearbeiten
      </h1>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          01. <span transloco="Persönliche Daten">Persönliche Daten</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Vorname')"
              [placeholder]="t('Vorname') + '...'"
              [fc]="firstNameControl"
              [hasError]="firstNameControl.invalid && firstNameControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('Nachname')"
              [placeholder]="t('Nachname') + '...'"
              [fc]="lastNameControl"
              [hasError]="lastNameControl.invalid && lastNameControl.touched"
              [errorMessage]="t('Required')"
            ></app-form-input>
          </div>
          <div class="lg:col-span-3">
            <app-form-input
              [label]="t('E-Mail-Adresse')"
              [placeholder]="t('E-Mail-Adresse') + '...'"
              [fc]="emailControl"
              [hasError]="emailControl.invalid && emailControl.touched"
              [errorMessage]="t('Ungültige E-Mail')"
            ></app-form-input>
          </div>
        </div>
        <div class="mt-6">
          <label class="flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              class="sr-only peer"
              [formControl]="enabledControl"
            />
            <div
              class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green7 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green7 peer-checked:after:bg-green5"
            ></div>
            <div
              class="ms-3 text-lg font-medium text-primary"
              transloco="Profile Aktiviert"
            >
              Profile Aktiviert
            </div>
          </label>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          02. <span transloco="Passwort">Passwort</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6" *ngIf="id">
          <div class="flex gap-2 items-center lg:col-span-6">
            <div transloco="Letzte Änderung:" class="text-gray16 text-sm">
              Letzte Änderung:
            </div>
            <div class="font-bold">
              {{ lastUpdatedAt | date: "dd.MM.yyyy" }}
            </div>
          </div>
          <div class="lg:col-span-6">
            <button
              transloco="Passwort ändern"
              class="text-orange1 mt-4 text-sm"
              (click)="openPasswordChangeModal()"
            >
              Passwort ändern
            </button>
          </div>
        </div>
        <div *ngIf="!id" class="flex gap-12 w-full">
          <div class="mt-1">
            <div
              class="text-sm text-primary font-medium"
              transloco="Passwort setzen"
            >
              Passwort setzen
            </div>
            <label class="flex items-center cursor-pointer mt-1">
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                [formControl]="setPasswordControl"
              />
              <div
                class="relative w-16 h-9 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green7 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-green7 peer-checked:after:bg-green5"
              ></div>
            </label>
          </div>
          <div class="w-full max-w-[300px]" *ngIf="setPasswordControl.value">
            <app-form-input
              [label]="t('Passwort')"
              [placeholder]="t('Passwort') + '...'"
              [fc]="passwordControl"
              [hasError]="passwordControl.invalid && passwordControl.touched"
              [errorMessage]="t('Ungültiges Passwort')"
              inputType="password"
            ></app-form-input>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          03. <span transloco="Rolle">Rolle</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
          <div class="lg:col-span-3">
            <div
              transloco="Rolle auswählen"
              class="pb-1 text-primary font-medium text-sm"
            >
              Rolle auswählen
            </div>
            <app-custom-dropdown [hasRawContent]="true">
              <div
                rawButton
                class="flex gap-1 items-center border border-gray10 rounded-xl focus:border-gray10 focus:ring-2 focus:ring-gray10 focus:outline-none p-3 text-sm w-full justify-between"
              >
                <div class="font-normal text-sm text-primary ml-1">
                  {{ selectedRole?.name }}
                </div>
                <img
                  src="assets/imgs/icons/chevron-down.svg"
                  alt=""
                  class="ml-3"
                />
              </div>
              <ng-container>
                <ul
                  class="flex flex-col px-3 py-2 rounded-lg bg-white min-w-[230px] shadow"
                >
                  <li
                    *ngFor="let role of filteredRoles"
                    class="flex w-full justify-start"
                  >
                    <button
                      (click)="roleIdControl.setValue(role.id)"
                      class="text-gray20 font-bold cursor-pointer py-2 px-2 hover:bg-gray-200 w-full text-start"
                    >
                      {{ role.name }}
                    </button>
                  </li>
                </ul>
              </ng-container>
            </app-custom-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-5 lg:gap-16 pt-8 pb-16 gap-y-8 border-b border-gray21"
      *isSuperAdmin
    >
      <div class="min-w-[150px] lg:col-span-2">
        <h2 class="text-lg font-medium leading-27.2px capitalize">
          04. <span transloco="Schule">Schule</span>
        </h2>
      </div>
      <div class="lg:col-span-3">
        <div class="grid grid-cols-1 lg:grid-cols-6 gap-6">
          <div class="lg:col-span-3">
            <div
              transloco="Schule auswählen"
              class="pb-1 text-primary font-medium text-sm"
            >
              Schule auswählen
            </div>
            <app-custom-dropdown [hasRawContent]="true">
              <div
                rawButton
                class="flex gap-1 items-center border border-gray10 rounded-xl focus:border-gray10 focus:ring-2 focus:ring-gray10 focus:outline-none p-3 text-sm w-full justify-between"
              >
                <div class="font-normal text-sm text-primary ml-1">
                  {{ selectedCustomer?.company }}
                </div>
                <img
                  src="assets/imgs/icons/chevron-down.svg"
                  alt=""
                  class="ml-3"
                />
              </div>
              <ng-container>
                <ul
                  class="flex flex-col px-3 py-2 rounded-lg bg-white min-w-[230px] shadow"
                >
                  <li
                    *ngFor="let customer of allCustomers"
                    class="flex w-full justify-start"
                  >
                    <button
                      (click)="customerIdControl.setValue(customer.id)"
                      class="text-gray20 font-bold cursor-pointer py-2 px-2 hover:bg-gray-200 w-full text-start"
                    >
                      {{ customer.company }}
                    </button>
                  </li>
                </ul>
              </ng-container>
            </app-custom-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-4 mt-6">
      <button
        transloco="Abbrechen"
        class="tw-btn-secondary-outline min-w-[140px]"
        [disabled]="userFormLoading"
        (click)="goBack()"
      >
        Abbrechen
      </button>
      <button
        transloco="Speichern"
        class="tw-btn-secondary min-w-[140px]"
        (click)="handleSubmit()"
        [disabled]="userFormLoading"
      >
        Speichern
      </button>
    </div>
  </div>
</div>
<app-change-password-modal
  [modalEvents]="modalEvents"
  mode="user-manager"
  [userId]="id"
></app-change-password-modal>
