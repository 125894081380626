import { AfterContentInit, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/backend-services/auth.service';
import { ModalEvents } from '../custom-modal/custom-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from '../../backend-services/user.service';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements AfterContentInit {
  @Input() modalEvents!: Subject<ModalEvents>;
  @Input() mode: 'profile' | 'user-manager' = 'profile';
  @Input() userId?: number | null;

  passwordDataForm = new FormGroup({
    password: new FormControl(''),
    new_password: new FormControl('', [
      Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    ]),
    confirm_password: new FormControl('', this.confirmPasswordValidator()),
  });
  INVALID_CURRENT_PASSWORD = false;
  passwordDataFormIsSubmitting = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private userService: UserService,
    private translocoService: TranslocoService
  ) {
    this.newPasswordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });
  }

  ngAfterContentInit(): void {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
      }
    });
  }

  get currentPasswordRequired() {
    return !(
      this.mode === 'user-manager' && this.authService.isSuperAdmin.value
    );
  }

  get passwordControl() {
    return this.passwordDataForm.get('password') as AbstractControl;
  }

  get newPasswordControl() {
    return this.passwordDataForm.get('new_password') as AbstractControl;
  }

  get confirmPasswordControl() {
    return this.passwordDataForm.get('confirm_password') as AbstractControl;
  }

  onPasswordChange() {
    // validate confirm password
    const confirmPasswordValidatorFn = this.confirmPasswordControl.validator;
    if (confirmPasswordValidatorFn) {
      confirmPasswordValidatorFn(this.confirmPasswordControl);
    }
  }

  confirmPasswordValidator(): ValidatorFn {
    return (
      confirmPasswordControl: AbstractControl
    ): { [key: string]: any } | null => {
      const passwordControl = this.passwordDataForm?.get('new_password');

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  savePassword() {
    this.INVALID_CURRENT_PASSWORD = false;
    this.passwordDataForm.markAllAsTouched();

    if (this.passwordDataForm.valid) {
      this.passwordDataFormIsSubmitting = true;
      this.passwordDataForm.disable({ emitEvent: true });

      if (this.mode === 'profile') {
        this.authService
          .changePassword({
            current_password: this.passwordDataForm.get('password')!.value,
            password: this.passwordDataForm.get('new_password')!.value,
          })
          .subscribe({
            next: () => {
              this.passwordDataFormIsSubmitting = false;
              this.passwordDataForm.enable({ emitEvent: true });

              this.toastr.success(
                this.translocoService.translate(`'Passwort wurde gespeichert`)
              );

              // reset the password form
              this.passwordDataForm.reset();
              this.modalEvents.next({ type: 'close' });
            },
            error: (res) => {
              if (res.error?.error === 'INVALID_CURRENT_PASSWORD') {
                this.INVALID_CURRENT_PASSWORD = true;
              } else {
                this.toastr.error(
                  res.error?.error ||
                    this.translocoService.translate(`'Something went wrong'`)
                );
              }

              this.passwordDataFormIsSubmitting = false;
              this.passwordDataForm.enable({ emitEvent: true });
            },
          });
      } else if (this.mode === 'user-manager' && this.userId) {
        this.userService
          .updateUser(this.userId, {
            password: this.passwordDataForm.get('new_password')!.value,
          })
          .subscribe({
            next: () => {
              this.passwordDataFormIsSubmitting = false;
              this.passwordDataForm.enable({ emitEvent: true });

              this.toastr.success(
                this.translocoService.translate(`'Passwort wurde gespeichert`)
              );

              // reset the password form
              this.passwordDataForm.reset();
              this.modalEvents.next({ type: 'close' });
            },
            error: (res) => {
              this.toastr.error(
                res.error?.error ||
                  this.translocoService.translate(`'Something went wrong'`)
              );

              this.passwordDataFormIsSubmitting = false;
              this.passwordDataForm.enable({ emitEvent: true });
            },
          });
      }
    }
  }
}
