import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { SortableTableHeaderComponent } from './sortable-table-header/sortable-table-header.component';
import { CompanyNameDisplayComponent } from './company-name-display/company-name-display.component';
import { PublicNavbarComponent } from './public-navbar/public-navbar.component';
import { RouterModule } from '@angular/router';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { AccordianItemComponent } from './accordian-item/accordian-item.component';
import { StatusDisplayComponent } from './status-display/status-display.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InputComponent } from './input/input.component';
import { LabelComponent } from './label/label.component';
import { FormInputComponent } from './form-input/form-input.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';

@NgModule({
  declarations: [
    PaginationComponent,
    SortableTableHeaderComponent,
    CompanyNameDisplayComponent,
    PublicNavbarComponent,
    CustomDropdownComponent,
    AccordianItemComponent,
    StatusDisplayComponent,
    CustomModalComponent,
    InputComponent,
    LabelComponent,
    FormInputComponent,
    ChangePasswordModalComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    PaginationComponent,
    SortableTableHeaderComponent,
    CompanyNameDisplayComponent,
    PublicNavbarComponent,
    CustomDropdownComponent,
    AccordianItemComponent,
    StatusDisplayComponent,
    CustomModalComponent,
    InputComponent,
    FormInputComponent,
    ChangePasswordModalComponent,
  ],
})
export class SharedComponentsModule {}
