import { Routes } from '@angular/router';
import { PageNotFoundComponent } from '../../page-not-found/page-not-found.component';
import { ProfileComponent } from '../../account/profile/profile.component';
import { UsersListViewPageComponent } from '../../user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from '../../user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from '../../user-management/customer/manage-single-customer.component';
import { AdminRoutes } from './admin-routes';
import { ContentManagementLayoutComponent } from '../../content-management/content-management-layout/content-management-layout.component';
import { ContentsListViewPageComponent } from '../../content-management/contents-list-view-page/contents-list-view-page.component';
import { SlotsManagementLayoutComponent } from '../../slots-management/slots-management-layout/slots-management-layout.component';
import { SlotsListviewPageComponent } from '../../slots-management/slots-listview-page/slots-listview-page.component';
import { IsNotSuperAdminGuard } from '../../auth/is-not-super-admin.guard';
import { ManageUsersCapabilityGuard } from '../../auth/manage-users-capability.guard';
import {IsSuperAdminGuard} from "../../auth/is-super-admin.guard";

export const AdminLayoutRoutes: Routes = [
  {
    path: AdminRoutes.Account,
    children: [{ path: AdminRoutes.MyProfile, component: ProfileComponent }],
  },
  {
    path: AdminRoutes.UserManagement,
    canActivate: [ManageUsersCapabilityGuard],
    children: [
      { path: '', component: UsersListViewPageComponent },
      {
        path: `${AdminRoutes.ManageSingleUser}`,
        component: ManageSingleUserComponent,
      },
      {
        path: `${AdminRoutes.ManageSingleUser}/:id`,
        component: ManageSingleUserComponent,
      },
      {
        path: `${AdminRoutes.ManageSingleCustomer}`,
        component: ManageSingleCustomerComponent,
        canActivate: [IsSuperAdminGuard]
      },
      {
        path: `${AdminRoutes.ManageSingleCustomer}/:id`,
        component: ManageSingleCustomerComponent,
        canActivate: [IsSuperAdminGuard]
      },
    ],
  },
  {
    path: AdminRoutes.ContentManagement,
    component: ContentManagementLayoutComponent,
    canActivate: [IsNotSuperAdminGuard],
    children: [
      { path: '', component: ContentsListViewPageComponent },
      {
        path: 'folders/:request_folder_id',
        component: ContentsListViewPageComponent,
      },
    ],
  },
  {
    path: AdminRoutes.SlotsManagement,
    canActivate: [IsNotSuperAdminGuard],
    component: SlotsManagementLayoutComponent,
    children: [{ path: '', component: SlotsListviewPageComponent }],
  },
  { path: '**', component: PageNotFoundComponent },
];

export declare interface RouteInfo {
  path: string;
  title?: string;
  class: string;
  iconName?: string;
  iconOutlined?: boolean;
  subRoutes?: Array<RouteInfo>;
}
