<!-- drawer component -->
<button class="hidden" #hideButton data-drawer-hide="side-drawer"></button>
<div
  id="side-drawer"
  class="fixed top-0 right-0 z-40 h-screen p-8 transition-transform translate-x-full text-gray10 w-[450px] bg-blue3"
  tabindex="-1"
  #drawerContainer
>
  <button
    type="button"
    data-drawer-hide="side-drawer"
    aria-controls="side-drawer"
    class="absolute top-10 -left-6"
    [ngClass]="{ hidden: !isOpen }"
  >
    <div
      class="bg-white text-black rounded-full w-12 h-12 flex items-center justify-center relative z-50"
    >
      <svg
        class="w-3 h-3"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </div>
    <span class="sr-only">Close menu</span>
  </button>
  <div class="flex w-full flex-col overflow-y-auto px-4">
    <div class="flex gap-4">
      <div class="text-primary w-10 h-10">
        <app-profile-picture
          [firstName]="authService.profile?.value?.first_name"
          [lastName]="authService.profile?.value?.last_name"
        ></app-profile-picture>
      </div>
      <div class="flex flex-col gap-1">
        <div class="font-semibold text-lg">
          {{ authService.profile?.value?.first_name }}
          {{ authService.profile?.value?.last_name }}
        </div>
        <div class="text-sm">{{ authService.profile?.value?.email }}</div>
      </div>
    </div>
  </div>
  <ul
    class="border-t border-b border-blue2 px-4 mt-10 pt-6 text-base font-semibold"
  >
    <li>
      <a
        routerLink="account/profile"
        class="mb-6 flex gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9" cy="4.5" r="3" fill="#DCE7EB" />
          <ellipse cx="9" cy="12.75" rx="5.25" ry="3" fill="#DCE7EB" />
        </svg>
        <span transloco="Profile">Profile</span></a
      >
    </li>
    <li *hasCapability="'MANAGE_USERS'">
      <a
        routerLink="/user-management"
        [queryParams]="{management_type: 'users'}"
        class="mb-6 flex gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.625 5.625C11.625 7.07475 10.4497 8.25 9 8.25C7.55025 8.25 6.375 7.07475 6.375 5.625C6.375 4.17525 7.55025 3 9 3C10.4497 3 11.625 4.17525 11.625 5.625Z"
            fill="#DCE7EB"
          />
          <path
            d="M13.5 12.375C13.5 13.8247 11.4853 15 9 15C6.51472 15 4.5 13.8247 4.5 12.375C4.5 10.9253 6.51472 9.75 9 9.75C11.4853 9.75 13.5 10.9253 13.5 12.375Z"
            fill="#DCE7EB"
          />
          <path
            d="M5.34154 3.75C5.47463 3.75 5.60457 3.76306 5.73004 3.78792C5.42437 4.33084 5.25 4.95756 5.25 5.625C5.25 6.27619 5.41598 6.88861 5.70795 7.42227C5.58934 7.44437 5.46684 7.45595 5.34154 7.45595C4.28073 7.45595 3.42077 6.62634 3.42077 5.60297C3.42077 4.5796 4.28073 3.75 5.34154 3.75Z"
            fill="#DCE7EB"
          />
          <path
            d="M4.0855 14.2395C3.65957 13.7303 3.375 13.1055 3.375 12.375C3.375 11.6668 3.64243 11.058 4.04684 10.5575C2.61832 10.6683 1.5 11.4497 1.5 12.397C1.5 13.3533 2.63798 14.1404 4.0855 14.2395Z"
            fill="#DCE7EB"
          />
          <path
            d="M12.7501 5.625C12.7501 6.27619 12.5841 6.88861 12.2921 7.42227C12.4107 7.44437 12.5332 7.45595 12.6585 7.45595C13.7193 7.45595 14.5793 6.62634 14.5793 5.60297C14.5793 4.5796 13.7193 3.75 12.6585 3.75C12.5254 3.75 12.3955 3.76306 12.27 3.78792C12.5757 4.33084 12.7501 4.95756 12.7501 5.625Z"
            fill="#DCE7EB"
          />
          <path
            d="M13.9146 14.2395C15.3621 14.1404 16.5001 13.3533 16.5001 12.397C16.5001 11.4497 15.3817 10.6683 13.9532 10.5575C14.3576 11.058 14.6251 11.6668 14.6251 12.375C14.6251 13.1055 14.3405 13.7303 13.9146 14.2395Z"
            fill="#DCE7EB"
          />
        </svg>
        <span transloco="Users">Users</span>
      </a>
    </li>
    <li *isSuperAdmin>
      <a
        routerLink="/user-management"
        [queryParams]="{management_type: 'customers'}"
        class="mb-6 flex gap-2 hover:opacity-80"
        (click)="hideDrawer()"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4.5C12 6.15685 10.6569 7.5 9 7.5C7.34315 7.5 6 6.15685 6 4.5C6 2.84315 7.34315 1.5 9 1.5C10.6569 1.5 12 2.84315 12 4.5Z"
            fill="#DCE7EB"
          />
          <path
            d="M11.7586 10.1271C11.4038 10.1314 11.0731 10.1443 10.7849 10.1831C10.3028 10.2479 9.77502 10.4028 9.33894 10.8389C8.90286 11.275 8.74792 11.8028 8.6831 12.2849C8.62484 12.7182 8.62492 13.2476 8.62501 13.8105V13.9395C8.62492 14.5024 8.62484 15.0318 8.6831 15.4651C8.72859 15.8034 8.81845 16.1642 9.01868 16.5C9.01246 16.5 9.00623 16.5 9 16.5C3 16.5 3 14.989 3 13.125C3 11.261 5.68629 9.75 9 9.75C9.99464 9.75 10.9327 9.88614 11.7586 10.1271Z"
            fill="#DCE7EB"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.1344 16.1156C10.5188 16.5 11.1376 16.5 12.375 16.5C13.6124 16.5 14.2312 16.5 14.6156 16.1156C15 15.7312 15 15.1124 15 13.875C15 12.6376 15 12.0188 14.6156 11.6344C14.2312 11.25 13.6124 11.25 12.375 11.25C11.1376 11.25 10.5188 11.25 10.1344 11.6344C9.75 12.0188 9.75 12.6376 9.75 13.875C9.75 15.1124 9.75 15.7312 10.1344 16.1156ZM11.6449 14.9824C11.1184 14.6053 10.5 14.1625 10.5 13.3948C10.5 12.5474 11.5313 11.9464 12.375 12.7611C13.2187 11.9464 14.25 12.5474 14.25 13.3948C14.25 14.1625 13.6316 14.6053 13.1051 14.9824C13.0503 15.0215 12.9966 15.06 12.9447 15.0981C12.75 15.2407 12.5625 15.375 12.375 15.375C12.1875 15.375 12 15.2407 11.8053 15.0981C11.7534 15.06 11.6997 15.0215 11.6449 14.9824Z"
            fill="#DCE7EB"
          />
        </svg>
        <span transloco="Schools">Schools</span>
      </a>
    </li>
    <li class="text-xs px-4 py-2" transloco="Language">Language</li>
    <li
      class="px-4 py-2 hover:bg-blue2"
      [ngClass]="{ 'bg-blue2': activeLang === 'en' }"
    >
      <button
        class="flex gap-2 items-center w-full"
        (click)="setLanguage('en')"
      >
        <img src="assets/imgs/lang/en.png" alt="en" class="w-6 h-4" />
        <span transloco="English">English</span>
      </button>
    </li>
    <li
      class="px-4 py-2 hover:bg-blue2 mb-6"
      [ngClass]="{ 'bg-blue2': activeLang === 'de' }"
    >
      <button
        class="flex gap-2 items-center w-full"
        (click)="setLanguage('de')"
      >
        <img src="assets/imgs/lang/de.png" alt="en" class="w-6 h-4" />
        <span transloco="German">German</span>
      </button>
    </li>
  </ul>
  <ul class="px-4 pt-6 text-base font-semibold">
    <li>
      <a
        routerLink="/settings"
        class="mb-6 flex gap-2 hover:opacity-80"
        (click)="logout()"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.625 7.17568V10.8243C2.625 12.5443 2.625 13.4043 3.16621 13.9386C3.6579 14.4241 4.42183 14.4685 5.86384 14.4726C5.78764 13.9668 5.77173 13.3626 5.76772 12.6517C5.76601 12.3494 6.01282 12.103 6.31896 12.1014C6.62511 12.0997 6.87468 12.3433 6.87638 12.6456C6.88088 13.4436 6.90187 14.0092 6.98173 14.4384C7.05869 14.852 7.18225 15.0914 7.36219 15.2691C7.56676 15.4711 7.85396 15.6027 8.39631 15.6747C8.9546 15.7488 9.69455 15.75 10.7555 15.75H11.4946C12.5556 15.75 13.2955 15.7488 13.8538 15.6747C14.3961 15.6027 14.6833 15.4711 14.8879 15.2691C15.0925 15.0671 15.2258 14.7836 15.2988 14.2481C15.3738 13.6969 15.375 12.9664 15.375 11.9189V6.08108C15.375 5.03362 15.3738 4.30307 15.2988 3.75187C15.2258 3.21641 15.0925 2.93286 14.8879 2.7309C14.6833 2.52894 14.3961 2.39726 13.8538 2.32527C13.2955 2.25116 12.5556 2.25 11.4946 2.25H10.7555C9.69455 2.25 8.9546 2.25116 8.39631 2.32527C7.85396 2.39726 7.56676 2.52894 7.36219 2.7309C7.18225 2.90855 7.05869 3.14796 6.98173 3.56158C6.90187 3.99084 6.88088 4.55644 6.87638 5.3544C6.87468 5.65666 6.62511 5.90032 6.31896 5.89864C6.01282 5.89696 5.76601 5.65056 5.76772 5.34831C5.77173 4.63742 5.78764 4.03318 5.86384 3.52744C4.42183 3.5315 3.6579 3.57592 3.16621 4.06136C2.625 4.59569 2.625 5.45569 2.625 7.17568ZM4.45039 9.387C4.2339 9.17326 4.2339 8.82674 4.45039 8.613L5.92863 7.15354C6.14511 6.93981 6.4961 6.93981 6.71258 7.15354C6.92907 7.36728 6.92907 7.71381 6.71258 7.92754L6.18066 8.4527L11.4945 8.4527C11.8006 8.4527 12.0488 8.69774 12.0488 9C12.0488 9.30226 11.8006 9.5473 11.4945 9.5473L6.18066 9.5473L6.71258 10.0725C6.92907 10.2862 6.92907 10.6327 6.71258 10.8465C6.4961 11.0602 6.14511 11.0602 5.92863 10.8465L4.45039 9.387Z"
            fill="#DCE7EB"
          />
        </svg>

        <span transloco="Logout">Logout</span>
      </a>
    </li>
  </ul>
</div>
