import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sortable-table-header',
  templateUrl: './sortable-table-header.component.html',
  styleUrls: ['./sortable-table-header.component.scss'],
})
export class SortableTableHeaderComponent implements OnInit {
  @Input() displayName = '';
  @Input() fieldName = '';
  constructor(private route: ActivatedRoute, private router: Router) {}

  queryParams: any = {};

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      this.queryParams = queryParams;
    });
  }

  changeSort() {
    // change sort direction if its the already sorted field, else just set field
    if (this.queryParams.sortBy === this.fieldName) {
      this.queryParams.sortDir =
        this.queryParams.sortDir === 'desc' ? 'asc' : 'desc';
    } else {
      this.queryParams.sortBy = this.fieldName;
      this.queryParams.sortDir = 'asc';
    }

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

}
