import {Component, HostListener, OnInit} from '@angular/core';
import { SlotEditModalService } from './slot-edit-modal.service';
import {
  AppContent,
  ContentService,
} from '../../backend-services/content.service';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import {calculateContainerSize, DEFAULT_PREVIEW_IMG_PARAMS} from "../../../util/preview-params-calculator";

@Component({
  selector: 'app-slot-edit-modal',
  templateUrl: './slot-edit-modal.component.html',
  styleUrls: ['./slot-edit-modal.component.scss'],
})
export class SlotEditModalComponent implements OnInit {
  availableContents: AppContent[] = [];

  selectedContent?: AppContent;
  selectedContentIsLoading = false;
  selectedContentFetchSubscription?: Subscription;

  imgParams = DEFAULT_PREVIEW_IMG_PARAMS;

  constructor(
    public slotEditModalService: SlotEditModalService,
    private contentService: ContentService,
    private translocoService: TranslocoService
  ) {
    this.contentService.getAllContents().subscribe((res) => {
      this.availableContents = res.data;
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.imgParams = calculateContainerSize();
  }

  ngOnInit(): void {
    this.imgParams = calculateContainerSize();

    this.slotEditModalService.contentIdControl.valueChanges.subscribe((v: any) => {
      this.selectedContentIsLoading = true;
      if (this.selectedContentFetchSubscription) {
        this.selectedContentFetchSubscription.unsubscribe();
      }

      if (!v) {
        this.selectedContent = undefined;
        this.selectedContentIsLoading = false;
      } else {
        this.selectedContentFetchSubscription = this.contentService
          .getContentById(v, {no_data_field: true})
          .subscribe((res) => {
            this.selectedContent = res.data;
            this.selectedContentIsLoading = false;
          });
      }
    })
  }

  getContentNameById(id?: number) {
    let content;
    if (id) {
      content = this.availableContents.find((c) => c.id === id);
    }

    return content?.title || this.translocoService.translate('Fallback');
  }
}
