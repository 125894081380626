import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { BehaviorSubject, tap } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';
import { AppContent } from './content.service';

export type RequestFolder = {
  id: number;
  title: string;
  customer_id: number;
  parent_id?: number;
  path?: [{ id: number; title: string }];
  children?: [{ id: number; title: string }];
};

@Injectable({
  providedIn: 'root',
})
export class RequestFolderService {
  // cache get folder by id requests
  cacheMap = new Map<string, { data: any; time: Dayjs }>();

  public localFolderNameMap = new Map<number, string>();

  constructor(private http: HttpClient, private authService: AuthService) {}

  checkCache(key: string, time: number) {
    if (
      this.cacheMap.has(key) &&
      this.cacheMap.get(key)!.time.isAfter(dayjs().subtract(5, 'minutes'))
    ) {
      return this.cacheMap.get(key)!.data;
    } else {
      return null;
    }
  }

  addToCache(key: string, data: any) {
    if (data.success) {
      this.cacheMap.set(key, { data: data, time: dayjs() });
    }
  }

  clearCache() {
    this.cacheMap.clear();
  }

  createRequestFolder(data: {
    title: string;
    customer_id: any;
    parent_id?: number;
  }) {
    let body: any = {
      title: data.title,
      parent_id: data.parent_id || undefined,
    };
    if (this.authService.isSuperAdmin.value) {
      body.customer_id = data.customer_id;
    }

    return this.http
      .post<{ success: true; data: RequestFolder }>(
        '/api/request_folders',
        body
      )
      .pipe(tap(() => this.clearCache()));
  }

  getFolderById(id: number) {
    type ResponseType = { success: true; data: RequestFolder };
    const url = `/api/request_folders/${id}`;

    const cachedData = this.checkCache(url, 5);
    if (cachedData) {
      return new BehaviorSubject(cachedData as ResponseType);
    }

    return this.http
      .get<ResponseType>(url)
      .pipe(tap((response) => this.addToCache(url, response)));
  }

  getRequestFolders() {
    type ResponseType = { success: true; data: RequestFolder[] };
    const url = '/api/request_folders';

    const cachedData = this.checkCache(url, 2);
    if (cachedData) {
      return new BehaviorSubject(cachedData as ResponseType);
    }

    return this.http.get<ResponseType>('/api/request_folders').pipe(
      tap((response) => {
        this.addToCache(url, response);

        const extractFolderNames = (folder: {
          id: number;
          title: string;
          children?: any;
        }) => {
          this.localFolderNameMap.set(folder.id, folder.title);
          folder.children?.forEach((child: any) => extractFolderNames(child));
        };

        response.data.forEach(extractFolderNames);
      })
    );
  }

  filterRequestFolders(params: any) {
    type ResponseType = {
      success: true;
      data: {
        total_folders: number;
        total_contents: number;
        total: number;
        folders: RequestFolder[];
        contents: AppContent[];
      };
    };

    let updatedParams: any = {};
    // remove undefined key&value pairs from query
    Object.entries(params)
      .filter(([k, v]) => v !== undefined)
      .forEach(([k, v]) => {
        updatedParams[k] = v;
      });

    const cacheKey =
      '/api/request_folders/filter' + JSON.stringify(updatedParams);
    const cachedData = this.checkCache(cacheKey, 2);
    if (cachedData) {
      return new BehaviorSubject(cachedData as ResponseType);
    }

    return this.http
      .get<ResponseType>('/api/request_folders/filter', {
        params: updatedParams,
      })
      .pipe(tap((response) => this.addToCache(cacheKey, response)));
  }

  updateRequestFolder(data: { id: number; title: string; parent_id?: number }) {
    let body: any = {
      title: data.title,
      parent_id: data.parent_id || undefined,
    };

    return this.http
      .put<{ success: true; data: RequestFolder }>(
        `/api/request_folders/${data.id}`,
        body
      )
      .pipe(tap(() => this.clearCache()));
  }

  deleteRequestFolder(id: number) {
    return this.http
      .delete<{ success: true }>(`/api/request_folders/${id}`)
      .pipe(tap(() => this.clearCache()));
  }

  copyFolder(id: number) {
    return this.http
      .post<{ success: true }>(`/api/request_folders/copy/${id}`, {})
      .pipe(tap(() => this.clearCache()));
  }
}
