import { Component, OnInit } from '@angular/core';
import 'reflect-metadata';
import { filter, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  navigationEnd: Observable<NavigationEnd>;

  constructor(
    private router: Router,
    private translocoService: TranslocoService
  ) {
    this.navigationEnd = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    const savedLang = localStorage.getItem('current_language');
    if (savedLang) {
      this.translocoService.setActiveLang(savedLang);
    }
  }

  ngOnInit() {
    initFlowbite();
    this.navigationEnd.subscribe(() => {
      initFlowbite();
    });
  }
}
