import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  RequestFolder,
  RequestFolderService,
} from '../../backend-services/request-folder.service';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CreateFolderModalService {
  public readonly modalEvents = new Subject<
    | ModalEvents
    | { type: 'new'; currentFolder?: { id: number; customer_id: number } }
    | { type: 'edit'; folder_id: number }
    | { type: 'close' }
    | { type: 'updated' }
    | { type: 'deleted' }
  >();

  public readonly deleteConfirmModalEvents = new Subject<
    ModalEvents | { type: 'init-delete'; folder_id: number }
  >();

  createFolderForm = new FormGroup({
    id: new FormControl(''),
    title: new FormControl('', [Validators.required]),
    customer_id: new FormControl('', []),
    parent_id: new FormControl(null, []),
  });
  isEditing = false;

  fullRequestFolderList: RequestFolder[] = [];
  availableParentFolderList: RequestFolder[] = [];
  folderToDeleteId?: number;
  folderToDeleteData?: RequestFolder;

  constructor(
    private toastr: ToastrService,
    private requestFolderService: RequestFolderService,
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {
    this.requestFolderService.getRequestFolders().subscribe({
      next: (res) => {
        this.fullRequestFolderList = res.data;
      },
      error: console.error,
    });

    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
          this.createFolderForm.reset();
          this.isEditing = false;
          if (event.currentFolder) {
            this.createFolderForm
              .get('parent_id')!
              .setValue(event.currentFolder.id);
            this.createFolderForm
              .get('customer_id')!
              .setValue(event.currentFolder.customer_id);
          }
          this.modalEvents.next({ type: 'open' });
          break;
        case 'edit':
          this.createFolderForm.reset();
          this.isEditing = true;

          this.requestFolderService
            .getFolderById(event.folder_id)
            .subscribe((res) => {
              this.createFolderForm.get('id')!.setValue(event.folder_id);
              this.createFolderForm
                .get('parent_id')!
                .setValue(res.data.parent_id);
              this.createFolderForm.get('title')!.setValue(res.data.title);
              this.createFolderForm
                .get('customer_id')!
                .setValue(res.data.customer_id);
              this.createFolderForm.get('customer_id')!.disable();
            });

          this.availableParentFolderList = this.filterAvailableParentFolderList(
            event.folder_id,
            this.fullRequestFolderList
          );
          this.modalEvents.next({ type: 'open' });

          break;
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.createFolderForm.reset();
          this.createFolderForm.get('customer_id')!.enable();
      }
    });

    this.deleteConfirmModalEvents.subscribe((event) => {
      switch (event.type) {
        case 'init-delete':
          this.folderToDeleteId = event.folder_id;
          this.requestFolderService
            .getFolderById(event.folder_id)
            .subscribe((res) => {
              this.folderToDeleteData = res.data;
            });

          this.deleteConfirmModalEvents.next({ type: 'open' });
          break;
        case 'init-close':
          this.deleteConfirmModalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.folderToDeleteId = undefined;
          this.folderToDeleteData = undefined;
      }
    });
  }

  handleSubmit() {
    if (this.createFolderForm.invalid) {
      this.toastr.error(this.translocoService.translate(`Title is required`));
      return;
    }

    if (!this.isEditing) {
      if (
        this.authService.isSuperAdmin.value &&
        !this.createFolderForm.value.customer_id
      ) {
        this.toastr.error(
          this.translocoService.translate(`Customer is required`)
        );
        return;
      }

      this.createFolderForm.disable();

      this.requestFolderService
        .createRequestFolder(this.createFolderForm.value)
        .subscribe({
          next: (res) => {
            this.createFolderForm.enable();
            this.toastr.success(
              this.translocoService.translate(`Folder Created Successfully`)
            );
            this.modalEvents.next({ type: 'updated' });
            this.modalEvents.next({ type: 'close' });
          },
          error: (err) => {
            console.log(err);
            this.toastr.error(
              this.translocoService.translate(`Unknown error occurred`)
            );
            this.createFolderForm.enable();
          },
        });
    } else {
      this.createFolderForm.disable();

      this.requestFolderService
        .updateRequestFolder(this.createFolderForm.value)
        .subscribe({
          next: () => {
            this.createFolderForm.enable();
            this.toastr.success(
              this.translocoService.translate(`Folder Updated Successfully`)
            );
            this.modalEvents.next({ type: 'updated' });
            this.modalEvents.next({ type: 'close' });
          },
          error: (err) => {
            console.log(err);
            this.toastr.error(
              this.translocoService.translate(`Unknown error occurred`)
            );
            this.createFolderForm.enable();
          },
        });
    }
  }

  get parentIdControl() {
    return this.createFolderForm.get('parent_id')! as FormControl;
  }

  filterAvailableParentFolderList(
    currentFolderId: number,
    folderList: any[]
  ): RequestFolder[] {
    let availableFolderList: any[] = [];
    folderList.forEach((folder) => {
      if (currentFolderId !== folder.id) {
        let filteredChildren = undefined;
        if (folder.children) {
          filteredChildren = this.filterAvailableParentFolderList(
            currentFolderId,
            folder.children
          );
        }
        folder.children = filteredChildren;
        availableFolderList.push(folder);
      }
    });

    return availableFolderList;
  }

  handleFolderDelete() {
    if (this.folderToDeleteId) {
      this.requestFolderService
        .deleteRequestFolder(this.folderToDeleteId)
        .subscribe({
          next: () => {
            this.toastr.info(this.translocoService.translate('Folder deleted'));
            this.deleteConfirmModalEvents.next({ type: 'close' });
            this.modalEvents.next({ type: 'updated' });
          },
        });
    }
  }
}
