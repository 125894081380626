<div
  class="grid grid-cols-1 lg:grid-cols-2 min-h-screen max-w-[1440px] mx-auto"
  *transloco="let t"
>
  <div class="flex justify-center items-center">
    <form
      action="#"
      novalidate
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="flex flex-col max-w-[310px]"
    >
      <img
        src="assets/imgs/school-logo.svg"
        alt="logo"
        class="w-[156px] mb-6"
      />
      <h1
        class="text-black text-3xl font-medium leading-43.2px mb-1"
        transloco="Anmelden"
      >
        Anmelden
      </h1>
      <p
        class="text-gray7 leading-6 tracking-0.08px mb-4"
        transloco="Welcome Back! Please enter your details."
      >
        Welcome Back! Please enter your details.
      </p>
      <div
        class="px-3 px2 text-white bg-semantic-danger-500 rounded-lg my-2"
        role="alert"
        *ngIf="hasLoginError"
        transloco="Ungültige E-Mail oder Passwort"
      >
        Ungültige E-Mail oder Passwort
      </div>
      <app-form-input
        [label]="t('E-Mail-Adresse')"
        [placeholder]="t('E-Mail-Adresse') + '...'"
        [fc]="emailControl"
        [hasError]="emailControl.invalid && emailControl.touched"
        [errorMessage]="t('Ungültige E-Mail')"
        class="mb-8"
      ></app-form-input>
      <app-form-input
        [label]="t('Passwort')"
        [placeholder]="t('Passwort') + '...'"
        [fc]="passwordControl"
        [hasError]="passwordControl.invalid && passwordControl.touched"
        [errorMessage]="t('Ungültiges Passwort')"
        inputType="password"
        class="mb-2"
      ></app-form-input>
      <div class="text-end text-orange1 mb-10">
        <a href="/forgot-password" transloco="Passwort vergessen?"
          >Passwort vergessen?</a
        >
      </div>
      <button
        class="tw-btn-secondary-v2"
        type="submit"
        [disabled]="formIsSubmitting"
        transloco="ANMELDEN"
      >
        ANMELDEN
      </button>
    </form>
  </div>
  <div class="hidden lg:flex p-6 justify-center items-center">
    <img
      src="assets/imgs/auth_bg.webp"
      alt=""
      class="object-cover w-full rounded-2xl max-h-[800px]"
    />
  </div>
</div>
