import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { ComponentsModule } from './layouts/admin-layout/components/components.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProfileComponent } from './account/profile/profile.component';
import { SettingsComponent } from './account/settings/settings.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
} from '@angular-material-components/color-picker';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BackendServicesModule } from './backend-services/backend-services.module';
import { AuthModule } from './auth/auth.module';
import { UsersListViewPageComponent } from './user-management/users-list-view-page/users-list-view-page.component';
import { ManageSingleUserComponent } from './user-management/user/manage-single-user.component';
import { ManageSingleCustomerComponent } from './user-management/customer/manage-single-customer.component';
import { RoleNameDisplayComponent } from './user-management/role-name-display/role-name-display.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { DirectivesModule } from './directives/directives.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PipesModule } from './pipes/pipes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ContentManagementModule } from './content-management/content-management.module';
import {SlotsManagementModule} from "./slots-management/slots-management.module";

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    DashboardComponent,
    ProfileComponent,
    SettingsComponent,
    PageNotFoundComponent,
    UsersListViewPageComponent,
    ManageSingleUserComponent,
    ManageSingleCustomerComponent,
    RoleNameDisplayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminLayoutModule,
    ComponentsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule,
    BackendServicesModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      closeButton: true,
      newestOnTop: true,
      timeOut: 4000,
      positionClass: 'toast-top-right',
    }),
    SharedComponentsModule,
    DirectivesModule,
    ClipboardModule,
    PipesModule,
    MatProgressSpinnerModule,
    TranslocoRootModule,
    TranslocoModule,
    ContentManagementModule,
    SlotsManagementModule,
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
  bootstrap: [AppComponent],
})
export class AppModule {}
