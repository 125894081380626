import { Component, Input, OnInit } from '@angular/core';
import { RoleService } from '../../backend-services/role.service';

@Component({
  selector: 'app-role-name-display',
  templateUrl: './role-name-display.component.html',
  styleUrls: ['./role-name-display.component.scss'],
})
export class RoleNameDisplayComponent implements OnInit {
  @Input() roleId?: number;
  roleName: string = '';

  constructor(private roleService: RoleService) {}

  ngOnInit(): void {
    if (this.roleId) {
      this.roleService.getRoleById(this.roleId).subscribe({
        next: (res) => {
          if (res) {
            this.roleName = res?.name;
          }
        },
        error: (err) => {
          this.roleName = err;
        },
      });
    }
  }
}
