import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotsListviewPageComponent } from './slots-listview-page/slots-listview-page.component';
import { SlotsManagementLayoutComponent } from './slots-management-layout/slots-management-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SlotEditModalComponent } from './slot-edit-modal/slot-edit-modal.component';

const slotsManagementRoutes: Routes = [
  {
    path: '',
    component: SlotsManagementLayoutComponent,
    children: [{ path: '', component: SlotsListviewPageComponent }],
  },
];

@NgModule({
  declarations: [SlotsListviewPageComponent, SlotsManagementLayoutComponent, SlotEditModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(slotsManagementRoutes),
    ReactiveFormsModule,
    DirectivesModule,
    TranslocoModule,
    SharedComponentsModule,
  ],
})
export class SlotsManagementModule {}
