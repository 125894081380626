import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { AppContent } from '../../backend-services/content.service';

@Injectable({
  providedIn: 'root',
})
export class ContentPreviewModalService {
  public readonly modalEvents = new Subject<
    ModalEvents | { type: 'init'; data: AppContent }
  >();

  currentContent?: AppContent;

  constructor() {
    this.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'init':
          this.currentContent = event.data;
          this.modalEvents.next({ type: 'open' });
          break;
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
          break;
      }
    });
  }
}
