<app-custom-modal
  classes="max-w-xl"
  [modalEvents]="modalEvents"
  *transloco="let t"
>
  <div header>
    <h1 class="text-28px font-medium leading-9 px-14" transloco="Passwort ändern">
      Passwort ändern
    </h1>
  </div>
  <div
    content-body
    class="flex justify-center flex-col items-center px-14 gap-6 mt-10"
  >
    <div class="w-full" *ngIf="currentPasswordRequired">
      <app-form-input
        [label]="t('Altes Passwort')"
        [placeholder]="t('Altes Passwort') + '...'"
        [fc]="passwordControl"
        [hasError]="INVALID_CURRENT_PASSWORD || (passwordControl.invalid && passwordControl.touched)"
        [errorMessage]="t('Ungültiges Passwort')"
        inputType="password"
        class="mb-2"
      ></app-form-input>
    </div>
    <div class="w-full">
      <app-form-input
        [label]="t('Neues Passwort')"
        [placeholder]="t('Neues Passwort') + '...'"
        [fc]="newPasswordControl"
        [hasError]="newPasswordControl.invalid && newPasswordControl.touched"
        [errorMessage]="t('Ungültiges Passwort')"
        inputType="password"
        class="mb-2"
      ></app-form-input>
    </div>
    <div class="w-full">
      <app-form-input
        [label]="t('Passwort wiederholen')"
        [placeholder]="t('Passwort wiederholen') + '...'"
        [fc]="confirmPasswordControl"
        [hasError]="
          confirmPasswordControl.invalid && confirmPasswordControl.touched
        "
        [errorMessage]="t('Passwords do not match')"
        inputType="password"
        class="mb-2"
      ></app-form-input>
    </div>
    <p
      class="text-gray7 leading-6 tracking-0.08px mt-2"
      transloco="Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten."
    >
      Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen
      Großbuchstaben und ein Sonderzeichen enthalten.
    </p>
    <button class="tw-btn-secondary w-full my-10" transloco="Passwort ändern" (click)="savePassword()" [disabled]="passwordDataFormIsSubmitting" >
      Passwort ändern
    </button>
  </div>
</app-custom-modal>
