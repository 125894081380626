import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

export type Role = {
  id: number;
  name: string;
  description?: string;
  level?: number;
  customer_id?: number;
};

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    private http: HttpClient,
    private translocoService: TranslocoService
  ) {}

  // cache get role by id requests
  roleCacheMap = new Map<number, BehaviorSubject<Role | undefined>>();

  getRoles() {
    return this.http.get<{ success: boolean; data: Role[] }>('/api/roles/');
  }

  getRoleById(id: number) {
    if (this.roleCacheMap.get(id)) {
      return this.roleCacheMap.get(id) as Observable<Role | undefined>;
    } else {
      return this.http
        .get<{ success: boolean; data: Role }>(`/api/roles/${id}`)
        .pipe((res) => {
          // create map item
          const ob = new BehaviorSubject<Role | undefined>(undefined);
          this.roleCacheMap.set(id, ob);

          res.subscribe({
            next: (res) => {
              if (res.success) {
                ob.next(res.data);
              }
            },
            error: (err) => {
              console.log(err);
              ob.error(this.translocoService.translate(`Error Occurred`));
            },
          });

          return ob;
        });
    }
  }
}
