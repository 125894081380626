import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserService } from '../../backend-services/user.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { AdminRoutes } from '../../layouts/admin-layout/admin-routes';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

const defaultParams: {
  management_type: string;
} = {
  management_type: 'users',
};

@Component({
  selector: 'app-users-list-view-page',
  templateUrl: './users-list-view-page.component.html',
  styleUrls: ['./users-list-view-page.component.scss'],
})
export class UsersListViewPageComponent implements OnDestroy {
  queryParams: any = { ...defaultParams };

  public ManageSingleUserRoute = AdminRoutes.ManageSingleUser;
  public ManageSingleCustomerRoute = AdminRoutes.ManageSingleCustomer;
  private subscriptions: any[] = [];

  allUsers: User[] = [];
  allCustomers: Customer[] = [];
  totalUsers = 0;
  totalCustomers = 0;

  public userSearchControl = new FormControl('');
  public userFilterControl = new FormControl('All');
  public schoolSearchControl = new FormControl('');
  public schoolFilterControl = new FormControl('All');

  modalEvents = new Subject<
    | { type: 'init-school-delete'; id: number; name: string }
    | { type: 'init-user-delete'; id: number; name: string }
    | ModalEvents
  >();
  pendingSchoolToDelete?: {
    id: number;
    name: string;
  };
  pendingUserToDelete?: {
    id: number;
    name: string;
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      queryParams.management_type =
        queryParams['management_type'] ?? this.queryParams.management_type;

      this.queryParams = queryParams;

      this.fetchData();
    });

    this.subscriptions.push(
      this.authService.profile.subscribe({
        next: () => {
          this.fetchData();
        },
      })
    );
    this.subscriptions.push(
      this.schoolFilterControl.valueChanges.subscribe((value) => {
        if (value === 'Aktiv') {
          this.queryParams.filter_status = 'enabled';
        } else if (value === 'Inaktiv') {
          this.queryParams.filter_status = 'disabled';
        } else {
          this.queryParams.filter_status = undefined;
        }

        this.handleQueryParamChange();
      })
    );
    this.subscriptions.push(
      this.userFilterControl.valueChanges.subscribe((value) => {
        if (value === 'Aktiv') {
          this.queryParams.filter_status = 'enabled';
        } else if (value === 'Inaktiv') {
          this.queryParams.filter_status = 'disabled';
        } else {
          this.queryParams.filter_status = undefined;
        }

        this.handleQueryParamChange();
      })
    );

    // listen to changes in the search control
    this.subscriptions.push(
      this.userSearchControl.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.queryParams.search = value;
          } else {
            this.queryParams.search = undefined;
          }

          this.handleQueryParamChange();
        })
    );
    this.subscriptions.push(
      this.schoolSearchControl.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.queryParams.search = value;
          } else {
            this.queryParams.search = undefined;
          }

          this.handleQueryParamChange();
        })
    );

    this.subscriptions.push(
      this.modalEvents.subscribe((ev) => {
        switch (ev.type) {
          case 'init-close':
            this.pendingSchoolToDelete = undefined;
            this.pendingUserToDelete = undefined;
            this.modalEvents.next({ type: 'close' });
            break;
          case 'init-school-delete':
            this.pendingSchoolToDelete = {
              id: ev.id,
              name: ev.name,
            };
            this.modalEvents.next({ type: 'open' });
            break;
          case 'init-user-delete':
            this.pendingUserToDelete = {
              id: ev.id,
              name: ev.name,
            };
            this.modalEvents.next({ type: 'open' });
            break;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  changeManagementType(newType: string) {
    this.queryParams.management_type = newType;

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  fetchData() {
    // fetch users
    if (this.queryParams.management_type === 'users') {
      this.userService.getUsers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allUsers = res.data;
            this.totalUsers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }

    if (
      this.queryParams.management_type === 'customers' &&
      this.authService.isSuperAdmin.value
    ) {
      // fetch customers if user is SuperAdmin
      this.customerService.getCustomers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allCustomers = res.data;
            this.totalCustomers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }
  }

  toggleCustomerActiveState(id: number, currentState: boolean) {
    this.customerService
      .updateCustomerEnabledState(id, !currentState)
      .subscribe(() => this.fetchData());
  }

  toggleUserActiveState(id: number, currentState: boolean) {
    this.userService
      .updateUser(id, { enabled: !currentState })
      .subscribe(() => this.fetchData());
  }

  initSchoolDelete(school: Customer) {
    this.modalEvents.next({
      type: 'init-school-delete',
      id: school.id,
      name: school.company!,
    });
  }

  initUserDelete(user: User) {
    this.modalEvents.next({
      type: 'init-user-delete',
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    });
  }

  hideDeleteModal() {
    this.modalEvents.next({ type: 'init-close' });
  }

  handleDeleteFromModal() {
    if (this.pendingUserToDelete) {
      this.handleUserDelete();
    }
    if (this.pendingSchoolToDelete) {
      this.handleSchoolDelete();
    }
  }

  handleSchoolDelete() {
    if (this.pendingSchoolToDelete) {
      this.customerService
        .deleteCustomer(this.pendingSchoolToDelete.id)
        .subscribe(() => {
          this.toastr.warning(
            this.translocoService.translate('School deleted')
          );
          this.fetchData();
          this.hideDeleteModal();
        });
    }
  }

  handleUserDelete() {
    if (this.pendingUserToDelete) {
      this.userService.deleteUser(this.pendingUserToDelete.id).subscribe(() => {
        this.toastr.warning(this.translocoService.translate('User deleted'));
        this.fetchData();
        this.hideDeleteModal();
      });
    }
  }

  simulateLogin(userId: number) {
    this.authService.simulateLogin(userId).subscribe({
      next: () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      error: (err) => {
        this.toastr.warning(err.message);
      },
    });
  }
}
